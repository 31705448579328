<template>
    <div class="CollMax">
        <!--最大的边框 -->
        <div class="CollBox" v-if="collet">
            <div style="width:100%;color:rgba(255, 255, 255, 1);font-size:4vh;margin-top: 2vh;">征集管理</div>
            <!-- 分类-->
            <div class="Law-Tab">
                <div class="Law-Tab-Nav">
                    <div v-for="(item, index) in NavList" :key="index" :class="{ btna: count == index }"
                        @click="change(index)" class="li">
                        <div style="width:100%;height: 100%;display: flex;align-items: center;justify-content: center;">
                            <div :class="{ actived: active == index }" class="Zt">{{ item }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 分类内容 -->
            <!-- 非物质文化遗产 -->
            <div class="end-cont" :class="{ dis: btnnum == 0 }">
                <!-- 表头 -->
                <div class="CollTbe">
                    <div class="CollTitle">类别</div>
                    <div class="CollTitle">所在景区</div>
                    <div class="CollTitle">名称</div>
                    <div class="CollTitleOne">审核状态</div>
                </div>

                <div style="width:100%;height:58vh; overflow: hidden;overflow-y: scroll;"
                    @mousewheel="CollectScroll($event)" ref="PersonDom">
                    <!-- 内容 -->
                    <div class="CollContent" v-for="(item, index) in ImmList" :key="index"
                        @click="list(item.heritageId, item.scenicId, item.categoryId)">
                        <div class="CollTd">戏剧类非物质文化遗产</div>
                        <div class="CollTd">{{ item.scenicName }}</div>
                        <div class="CollTd">{{ item.title }}</div>
                        <div class="CollTdOne"
                            :style="{ 'color': item.checkStatus == -1 ? 'rgba(255, 175, 175, 1)' : item.checkStatus == 0 ? 'rgba(255, 255, 255, 1)' : item.checkStatus == 1 ? 'rgba(173, 206, 255, 1)' : item.checkStatus == 2 ? 'rgba(123, 255, 207, 1)' : '' }">
                            {{ item.checkStatus == -1 ? '审核不通过' : item.checkStatus == 0 ? '未提交' : item.checkStatus == 1 ? '审核中' :
                                item.checkStatus == 2 ? '审核通过' : '' }} </div>
                    </div>
                </div>
            </div>
            <!-- 红色文化 -->
            <div class="end-cont" :class="{ dis: btnnum == 1 }">
                <!-- 表头 -->
                <div class="CollTbe">
                    <div class="CollTitle">类别</div>
                    <div class="CollTitle">所在景区</div>
                    <div class="CollTitle">名称</div>
                    <div class="CollTitleOne">审核状态</div>
                </div>

                <div style="width:100%;height:60vh; overflow: hidden;overflow-y: scroll;"
                    @mousewheel="CultureScroll($event)" ref="PersonDomOne">
                    <!-- 内容 -->
                    <div class="CollContent" v-for="(item, index) in CultureList" :key="index"
                        @click="ListOne(item.personageId, item.scenicId)">
                        <div class="CollTd">红色人物</div>
                        <div class="CollTd">{{ item.scenicName }}</div>
                        <div class="CollTd">{{ item.title }}</div>
                        <div class="CollTdOne"
                            :style="{ 'color': item.checkStatus == -1 ? 'rgba(255, 175, 175, 1)' : item.checkStatus == 0 ? 'rgba(255, 255, 255, 1)' : item.checkStatus == 1 ? 'rgba(173, 206, 255, 1)' : item.checkStatus == 2 ? 'rgba(123, 255, 207, 1)' : '' }">
                            {{ item.checkStatus == -1 ? '审核不通过' : item.checkStatus == 0 ? '未提交' : item.checkStatus == 1 ? '审核中' :
                                item.checkStatus == 2 ? '审核通过' : '' }} </div>
                    </div>
                </div>
            </div>
            <!-- 红色影像 -->
            <div class="end-cont" :class="{ dis: btnnum == 2 }">
                <!-- 表头 -->
                <div class="CollTbe">
                    <div class="CollTitle">类别</div>
                    <div class="CollTitle">所在景区</div>
                    <div class="CollTitle">名称</div>
                    <div class="CollTitleOne">审核状态</div>
                </div>

                <div style="width:100%;height:60vh; overflow: hidden;overflow-y: scroll;"
                    @mousewheel="ArchiteScroll($event)" ref="PersonDomTwo">
                    <!-- 内容  :style="{ 'color': pass == 0 ? 'rgba(123, 255, 207, 1)' : pass == 1 ? 'rgba(173, 206, 255, 1)' : pass == 2 ? 'rgba(255, 175, 175, 1)' : '' }"-->
                    <div class="CollContent" v-for="(item, index) in AreaList" :key="index">
                        <div class="CollTd">红色影像</div>
                        <div class="CollTd">{{ item.scenicName }}</div>
                        <div class="CollTd">{{ item.title }}</div>
                        <div class="CollTdOne"
                            :style="{ 'color': item.checkStatus == -1 ? 'rgba(255, 175, 175, 1)' : item.checkStatus == 0 ? 'rgba(255, 255, 255, 1)' : item.checkStatus == 1 ? 'rgba(173, 206, 255, 1)' : item.checkStatus == 2 ? 'rgba(123, 255, 207, 1)' : '' }">
                            {{ item.checkStatus == -1 ? '审核不通过' : item.checkStatus == 0 ? '未提交' : item.checkStatus == 1 ? '审核中' :
                                item.checkStatus == 2 ? '审核通过' : '' }} </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 非物质文化遗产修改 -->
        <div class="CollBoxOne" v-if="ColletOne">
            <div
                style="width:100%;height:5vh; color:rgba(255, 255, 255, 1);margin-top: 2vh;display:flex;justify-content: space-between;">
                <div style="font-size:3vh;">非物质文化遗产</div>
                <!-- 确认 取消按钮 -->
                <div style="width:15%;height:100%;display: flex;justify-content: space-between;">
                    <!-- 取消 -->
                    <div @click="cancel()"
                        style="width:45%;height:100%;background-color:rgba(173, 173, 173, 1);display: flex;justify-content: center;align-items: center;cursor: pointer;">
                        取消</div>
                    <!-- 确认 -->
                    <div v-if="EditIndexOne" @click="edit()"
                        style="width:45%;height:100%;background-color:rgba(132, 43, 43, 1);display: flex;justify-content: center;align-items: center;cursor: pointer;">
                        编辑</div>
                    <div v-else @click="save()"
                        style="width:45%;height:100%;background-color:rgba(132, 43, 43, 1);display: flex;justify-content: center;align-items: center;cursor: pointer;">
                        保存</div>
                </div>
            </div>
            <div class="CharBox">
                <!-- 下面 -->
                <div class="RedBelow">
                    <!-- 左边 -->
                    <div class="RedLeft">
                        <!-- 复制 -->
                        <div class="RedName" v-if="intangible">
                            <div style="color:rgba(255, 255, 255, 1); font-size: 1.8vh;margin-left:3vh;">非文化物质遗产名称：</div>
                            <input type="text" v-model="CharForm.name" readonly>
                        </div>
                        <!-- 名称 -->
                        <div class="RedName" v-else>
                            <div style="color:rgba(255, 255, 255, 1); font-size: 1.8vh;margin-left:3vh;">非文化物质遗产名称：</div>
                            <input type="text" v-model="CharForm.name">
                        </div>
                        <div style="width:100%;  font-size:2vh;color:rgba(255, 255, 255, 1);font-size: 1.8vh;margin-left:3vh;margin-top:3vh;"
                            v-if="intangible">
                            非文化物质遗产所在地：</div>
                        <el-form class="OrdSiteOne" inline :rules="rules" :model="ruleForm" ref="ruleForm"
                            v-if="intangible">
                            <div class="OrRedClass">
                                <!-- 省 -->
                                <el-form-item prop="province" class="OrItem">
                                    <el-select v-model="ruleForm.province" placeholder="请选择" style="width:12vw;" disabled>
                                        <el-option v-for="(item, index) in province" :key="index" :label="item.areaName"
                                            :value="item.areaId" @click="selectProvince(item.areaId)">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <!-- 市 -->
                                <el-form-item prop="city">
                                    <el-select v-model="ruleForm.cityValue" placeholder="请选择" @change="selectcity"
                                        style="width:12vw" disabled>
                                        <el-option v-for="(item, index) in cityarr" :key="index" :label="item.areaName"
                                            :value="item.areaId" @click="selectCountry(item.areaId)"></el-option>
                                    </el-select>
                                </el-form-item>
                                <!-- 县 -->
                                <el-form-item prop="country">
                                    <el-select v-model="ruleForm.RegionValue" placeholder="请选择" style="width:12vw" disabled>
                                        <el-option v-for="(item, index) of regionarr" :key="index" :label="item.areaName"
                                            :value="item.areaId" @click="district(item.areaId)">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                        </el-form>
                        <!-- 非文化物质遗产所在地： -->
                        <div style="width:100%;  font-size:2vh;color:rgba(255, 255, 255, 1);font-size: 1.8vh;margin-left:3vh;margin-top:3vh;"
                            v-if="DdZi">
                            非文化物质遗产所在地：</div>
                        <el-form class="OrdSiteOne" inline :rules="rules" :model="ruleForm" ref="ruleForm" v-if="DdZi">
                            <div class="OrRedClass">
                                <!-- 省 -->
                                <el-form-item prop="province" class="OrItem">
                                    <el-select v-model="ruleForm.province" placeholder="请选择" style="width:12vw;">
                                        <el-option v-for="(item, index) in province" :key="index" :label="item.areaName"
                                            :value="item.areaId" @click="selectProvince(item.areaId)">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <!-- 市 -->
                                <el-form-item prop="city">
                                    <el-select v-model="ruleForm.cityValue" placeholder="请选择" @change="selectcity"
                                        style="width:12vw">
                                        <el-option v-for="(item, index) in cityarr" :key="index" :label="item.areaName"
                                            :value="item.areaId" @click="selectCountry(item.areaId)"></el-option>
                                    </el-select>
                                </el-form-item>
                                <!-- 县 -->
                                <el-form-item prop="country">
                                    <el-select v-model="ruleForm.RegionValue" placeholder="请选择" style="width:12vw">
                                        <el-option v-for="(item, index) of regionarr" :key="index" :label="item.areaName"
                                            :value="item.areaId" @click="district(item.areaId)">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                        </el-form>
                        <!-- <div class="RedName" v-else>
                        <div style="color:rgba(255, 255, 255, 1); font-size: 1.8vh;margin-left:3vh;">非文化物质遗产所在地：</div>
                        <input type="text" v-model="CharForm.born">
                    </div> -->
                        <!-- 复制 -->
                        <div class="RedNameOne" v-if="intangible">
                            <div style="color:rgba(255, 255, 255, 1); font-size: 1.8vh;margin-left:3vh;">非遗类别：</div>
                            <input type="text" v-model="CharForm.CollectionCat" readonly>
                        </div>
                        <!-- 类别 -->
                        <div class="RedNameOne" v-else>
                            <div style="color:rgba(255, 255, 255, 1); font-size: 1.8vh;margin-left:3vh;">非遗类别：</div>
                            <el-select v-model="CharForm.CollectionCat" placeholder="请选择" style="width:70%;height:75%;">
                                <el-option v-for="(item, index) in CollectionCategoryOne" :key="index"
                                    :label="item.categoryName" :value="item.categoryId" class="provinces_select"
                                    @click="CollectionCateg(item.categoryId)">
                                </el-option>
                            </el-select>
                        </div>
                        <!-- 复制 -->
                        <div class="RedNameOne" v-if="intangible">
                            <!-- 所属景区: -->
                            <div style="color:rgba(255, 255, 255, 1); font-size: 1.8vh;margin-left:3vh;">所属景区：</div>
                            <input type="text" v-model="CharForm.scenic" readonly>
                        </div>
                        <!-- 所属景区 -->
                        <div class="RedNameOne" v-else>
                            <!-- 所属景区: -->
                            <div style="color:rgba(255, 255, 255, 1); font-size: 1.8vh;margin-left:3vh;">所属景区：</div>
                            <el-select v-model="CharForm.scenic" placeholder="请选择" style="width:70%;height:75%;">
                                <el-option v-for="(item, index) in ScenicArea" :key="index" :label="item.scenicName"
                                    :value="item.scenicId" class="provinces_select" @click="ScenicId(item.scenicId)">
                                </el-option>
                            </el-select>
                        </div>
                        <!-- 复制 -->
                        <div class="CharFlexOne" v-if="intangible">
                            <!-- 视频名称-->
                            <div class="RedPicture">
                                <div style="color:rgba(255, 255, 255, 1); font-size: 1.8vh;margin-left:3vh;">视频名称：</div>
                                <input type="text" v-model="CharForm.VideoName" readonly>
                            </div>
                            <!-- 视频名称 -->
                            <div class="RedPicture">
                                <div style="color:rgba(255, 255, 255, 1); font-size: 1.8vh;margin-left:3vh;">视频名称：</div>
                                <input type="text" v-model="CharForm.VideoNameOne" readonly>
                            </div>
                        </div>
                        <div class="CharFlexOne" v-else>
                            <!-- 视频名称-->
                            <div class="RedPicture">
                                <div style="color:rgba(255, 255, 255, 1); font-size: 1.8vh;margin-left:3vh;">视频名称：</div>
                                <input type="text" v-model="CharForm.VideoName">
                            </div>
                            <!-- 视频名称 -->
                            <div class="RedPicture">
                                <div style="color:rgba(255, 255, 255, 1); font-size: 1.8vh;margin-left:3vh;">视频名称：</div>
                                <input type="text" v-model="CharForm.VideoNameOne">
                            </div>
                        </div>
                        <!-- 上传 -->
                        <div class="RedUploading">
                            <!-- 上传视频/图片： -->
                            <div class="RedCharacters">
                                <!-- 图片 -->
                                <!-- <div class="RedPictureOne">上传图片：</div> -->
                                <!-- 图片 -->
                                <div class="RedPictureOne">上传视频：</div>
                            </div>
                            <!-- 图片 商品 -->
                            <div class="RedFlex">
                                <!-- 上传视频 -->
                                <div class="RedVideo" v-if="IntanShow">

                                    <el-upload action="http://ccsu.lzxx8848.com/api/tools/fileUpload/videoUpload"
                                        :limit="limitCountImgOne" class="dl-avatar-uploader-min square"
                                        :class="{ uoloadBtn: showBtnDealImgOne, disUoloadBtn: noneBtnImgOne }"
                                        :on-change="onChangeUploadTwo" list-type="picture-card" :file-list="FileListOne"
                                        :headers="headers" :data="VideoData" :on-preview="handlePreviewTwo"
                                        :on-exceed="handleExceedCoverTwo" :before-upload="beforeImageUploadTwo"
                                        :on-remove="coverFileRemoveTwo" :on-success="coverFileSuccessTwo">
                                        <div style="width:100%;height:100%;"> <img
                                                src="../../../assets/PersonalCenter/collect/uploading.png" alt=""
                                                style="width:100%;height:100%;"> </div>
                                    </el-upload>
                                    <el-dialog v-model="dialogVisibleOne">
                                        <video controls="controls" style="width: 100%;">
                                            <source :src="dialogImageUrlOne" type="video/mp4" />
                                        </video>
                                        <!-- <video :src="dialogImageUrlOne" class="video" controls="controls" ></video> -->
                                        <!-- <img w-full class="image onsite-img" :src="dialogImageUrl" /> -->
                                    </el-dialog>

                                </div>
                                <!-- 复制 -->
                                <!-- 视频 -->
                                <div style="width:80%;height:100%;display: flex;justify-content: space-between;"
                                    v-if="intangible">
                                    <!-- 第一个视频 -->
                                    <div style=" width:9vw;height:100%;cursor: pointer;">
                                        <video :src="videoListOne" style="width:100%;height:100%;margin-top:-3vh;"></video>
                                    </div>
                                    <!-- 第二个视频 -->
                                    <div style=" width:9vw;height:100%;cursor: pointer;" v-if="IntanVideoOne">
                                        <video :src="videoListTwo" style="width:100%;height:100%;margin-top:-3vh;"></video>
                                    </div>
                                </div>
                                <!-- 视频 -->
                                <div style="width:80%;height:100%;display: flex;justify-content: space-between;" v-else>
                                    <!-- 第一个视频 -->
                                    <div style=" width:9vw;height:100%;cursor: pointer;" @click="intan()" v-if="IntanVideo">
                                        <video :src="videoListOne" style="width:100%;height:100%;margin-top:-3vh;"></video>
                                    </div>
                                    <!-- 第二个视频 -->
                                    <div style=" width:9vw;height:100%;cursor: pointer;" @click="IntanOne()"
                                        v-if="IntanVideoOne">
                                        <video :src="videoListTwo" style="width:100%;height:100%;margin-top:-3vh;"></video>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <!-- 复制 -->
                        <div class="ChaVideoIntrodu" v-if="intangible">
                            <div class="ChaTitle">视频简介1：</div>
                            <textarea v-model="videoForm[0].VideoPoint" placeholder="请输入简介" class="RedCollText" readonly>

                              </textarea>
                            <div class="ChaTitle">视频简介2：</div>
                            <textarea v-model="videoForm[1].VideoPoint" placeholder="请输入简介" class="RedCollText" readonly>

                              </textarea>
                        </div>
                        <!-- 视频简介 -->
                        <div class="ChaVideoIntrodu" v-else>
                            <div class="ChaTitle">视频简介1：</div>
                            <textarea v-model="videoForm[0].VideoPoint" placeholder="请输入简介" class="RedCollText">

                              </textarea>
                            <div class="ChaTitle">视频简介2：</div>
                            <textarea v-model="videoForm[1].VideoPoint" placeholder="请输入简介" class="RedCollText">

                              </textarea>
                        </div>
                    </div>
                    <!-- 右边 -->
                    <div class="RedRight">
                        <div class="RedTextBox" v-if="intangible">
                            <div class="ChaTitle">人物简介：</div>
                            <textarea v-model="CharForm.inputPoint" placeholder="请输入简介" class="RedCollText" readonly>

                              </textarea>
                        </div>
                        <div class="RedTextBox" v-else>
                            <div class="ChaTitle">人物简介：</div>
                            <textarea v-model="CharForm.inputPoint" placeholder="请输入简介" class="RedCollText">

                              </textarea>
                        </div>

                        <!-- 上传 -->
                        <!-- 复制 -->
                        <div class="CharFlexTwo" v-if="intangible">
                            <!-- 图片名称-->
                            <div class="RedPicture">
                                <div style="color:rgba(255, 255, 255, 1); font-size: 1.8vh;margin-left:3vh;">图片名称：</div>
                                <input type="text" v-model="CharForm.ImgName" readonly>
                            </div>
                            <!-- 视频名称 -->
                            <div class="RedPicture">
                                <div style="color:rgba(255, 255, 255, 1); font-size: 1.8vh;margin-left:3vh;">图片名称：</div>
                                <input type="text" v-model="CharForm.ImgNameOne" readonly>
                            </div>
                        </div>
                        <!-- 图片，视频名称 -->
                        <div class="CharFlexTwo" v-else>
                            <!-- 图片名称-->
                            <div class="RedPicture">
                                <div style="color:rgba(255, 255, 255, 1); font-size: 1.8vh;margin-left:3vh;">图片名称：</div>
                                <input type="text" v-model="CharForm.ImgName">
                            </div>
                            <!-- 视频名称 -->
                            <div class="RedPicture">
                                <div style="color:rgba(255, 255, 255, 1); font-size: 1.8vh;margin-left:3vh;">图片名称：</div>
                                <input type="text" v-model="CharForm.ImgNameOne">
                            </div>
                        </div>
                        <div class="CharRight">
                            <div class="RedCharacters">
                                <!-- 图片 -->
                                <div class="RedPictureOne">上传图片：</div>
                            </div>
                            <!-- 上传图片 -->
                            <div class="RedRedPictureTwo">
                                <div class="RedVideo" v-if="IntanShowTher">
                                    <el-upload action="http://ccsu.lzxx8848.com/api/tools/fileUpload/imgUpload"
                                        :limit="limitCountImg" class="dl-avatar-uploader-min square"
                                        :class="{ uoloadBtn: showBtnDealImg, disUoloadBtn: noneBtnImg }"
                                        :on-change="onChangeUploadTher" list-type="picture-card" :file-list="fileList"
                                        :headers="headers" :data="FormData" :on-preview="handlePreviewTher"
                                        accept="image/jpeg,image/png,image/jpg" :on-exceed="handleExceedCoverTher"
                                        :before-upload="beforeImageUploadTher" :on-remove="coverFileRemoveTher"
                                        :on-success="coverFileSuccessTher">
                                        <div style="width:100%;height:100%;"> <img
                                                src="../../../assets/PersonalCenter/collect/uploading.png" alt=""
                                                style="width:100%;height:100%;"> </div>
                                    </el-upload>
                                    <el-dialog v-model="dialogVisible">
                                        <img w-full class="image onsite-img" :src="dialogImageUrl" />
                                    </el-dialog>
                                </div>
                                <!-- 复制 -->
                                <div style="width:80%;height:100%;display: flex;justify-content: space-between;"
                                    v-if="intangible">
                                    <!-- 第一个图片 -->
                                    <div style=" width:11vh;height:11vh;cursor: pointer;">
                                        <img :src="PictureUrlTwo" style="width:100%;height:100%;">
                                    </div>
                                    <!-- 第二个图片 -->
                                    <div style=" width:11vh;height:11vh;cursor: pointer;">
                                        <img :src="PictureUrlTher" style="width:100%;height:100%;">
                                    </div>
                                </div>
                                <!-- 图片 -->
                                <div style="width:80%;height:100%;display: flex;justify-content: space-between;" v-else>
                                    <!-- 第一个图片 -->
                                    <div style=" width:11vh;height:11vh;cursor: pointer;" @click="PictureIntan()"
                                        v-if="IntanPicture">
                                        <img :src="PictureUrlTwo" style="width:100%;height:100%;">
                                    </div>
                                    <!-- 第二个图片 -->
                                    <div style=" width:11vh;height:11vh;cursor: pointer;" @click="PictureIntanOne()"
                                        v-if="IntanPictureOne">
                                        <img :src="PictureUrlTher" style="width:100%;height:100%;">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- 复制 -->
                        <div class="ChaVideoIntrodu" v-if="intangible">
                            <div class="ChaTitle">图片简介1：</div>
                            <textarea v-model="imgsTwo[0].ImgsPoint" placeholder="请输入简介" class="RedCollText" readonly>

                              </textarea>
                            <div class="ChaTitle">图片简介2：</div>
                            <textarea v-model="imgsTwo[1].ImgsPoint" placeholder="请输入简介" class="RedCollText" readonly>

                              </textarea>
                        </div>
                        <!-- 图片简介 -->
                        <div class="ChaVideoIntrodu" v-else>
                            <div class="ChaTitle">图片简介1：</div>
                            <textarea v-model="imgsTwo[0].ImgsPoint" placeholder="请输入简介" class="RedCollText">

                              </textarea>
                            <div class="ChaTitle">图片简介2：</div>
                            <textarea v-model="imgsTwo[1].ImgsPoint" placeholder="请输入简介" class="RedCollText">

                              </textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 红色人物修改 -->
        <div class="CollBoxTwo" v-if="ColletTwo">
            <div
                style="width:100%;height:5vh; color:rgba(255, 255, 255, 1);margin-top: 2vh;display:flex;justify-content: space-between;">
                <div style="font-size:3vh;">红色人物</div>
                <!-- 确认 取消按钮 -->
                <div style="width:15%;height:100%;display: flex;justify-content: space-between;">
                    <!-- 取消 -->
                    <div @click="CancelOne()"
                        style="width:45%;height:100%;background-color:rgba(173, 173, 173, 1);display: flex;justify-content: center;align-items: center;cursor: pointer;">
                        取消</div>
                    <!-- 确认 -->
                    <div v-if="editIndex" @click="EditOne()"
                        style="width:45%;height:100%;background-color:rgba(132, 43, 43, 1);display: flex;justify-content: center;align-items: center;cursor: pointer;">
                        编辑</div>
                    <div v-else @click="SaveOne()"
                        style="width:45%;height:100%;background-color:rgba(132, 43, 43, 1);display: flex;justify-content: center;align-items: center;cursor: pointer;">
                        保存</div>
                </div>
            </div>
            <!-- 输入框 -->
            <div class="CharBox">

                <!-- 下面 -->
                <div class="RedBelow">
                    <!-- 左边 -->
                    <div class="RedLeft">
                        <!-- 复制 -->
                        <div class="RedName" v-if="CollInput">
                            <div style="color:rgba(255, 255, 255, 1); font-size: 1.8vh;margin-left:3vh;">人物名：</div>
                            <input type="text" v-model="CharFormOne.NameOne" readonly="readonly">
                        </div>
                        <!-- 名称 -->
                        <div class="RedName" v-else>
                            <div style="color:rgba(255, 255, 255, 1); font-size: 1.8vh;margin-left:3vh;">人物名：</div>
                            <input type="text" v-model="CharFormOne.NameOne">
                        </div>
                        <!-- 复制 -->
                        <div class="RedNameOne" v-if="CollInput">
                            <!-- 所属景区: -->
                            <div style="color:rgba(255, 255, 255, 1); font-size: 1.8vh;margin-left:3vh;">
                                所属景区：
                            </div>
                            <input type="text" v-model="CharFormOne.scenic" readonly="readonly">
                            <!-- <el-select v-model="CharFormOne.scenic" placeholder="请选择" style="width:70%;height:60%;">
                            <el-option v-for="(item, index) in ScenicArea" :key="index" :label="item.scenicName"
                                :value="item.scenicId" class="provinces_select" @click="ScenicId(item.scenicId)">
                            </el-option>
                        </el-select> -->
                            <!-- <div style="color:rgba(255, 255, 255, 1); font-size: 1.8vh;margin-left:3vh;">所属景区：</div>
                        <input type="text" @blur="scenic" v-model="CharForm.scenic"> -->
                        </div>
                        <div class="RedNameOne" v-else>
                            <!-- 所属景区: -->
                            <div style="color:rgba(255, 255, 255, 1); font-size: 1.8vh;margin-left:3vh;">所属景区：</div>
                            <el-select v-model="CharFormOne.scenic" placeholder="请选择" style="width:70%;height:60%;">
                                <el-option v-for="(item, index) in ScenicArea" :key="index" :label="item.scenicName"
                                    :value="item.scenicId" class="provinces_select" @click="ScenicId(item.scenicId)">
                                </el-option>
                            </el-select>
                            <!-- <div style="color:rgba(255, 255, 255, 1); font-size: 1.8vh;margin-left:3vh;">所属景区：</div>
                        <input type="text" @blur="scenic" v-model="CharForm.scenic"> -->
                        </div>
                        <!-- 复制 -->
                        <div class="CharFlexOne" v-if="CollInput">
                            <!-- 视频名称-->
                            <div class="RedPicture">
                                <div style="color:rgba(255, 255, 255, 1); font-size: 1.8vh;margin-left:3vh;">视频名称：</div>
                                <input type="text" v-model="CharFormOne.VideoName" readonly="readonly">
                            </div>
                            <!-- 视频名称 -->
                            <div class="RedPicture">
                                <div style="color:rgba(255, 255, 255, 1); font-size: 1.8vh;margin-left:3vh;">视频名称：</div>
                                <input type="text" v-model="CharFormOne.VideoNameOne" readonly="readonly">
                            </div>
                        </div>
                        <div class="CharFlexOne" v-else>
                            <!-- 视频名称-->
                            <div class="RedPicture">
                                <div style="color:rgba(255, 255, 255, 1); font-size: 1.8vh;margin-left:3vh;">视频名称：</div>
                                <input type="text" v-model="CharFormOne.VideoName">
                            </div>
                            <!-- 视频名称 -->
                            <div class="RedPicture">
                                <div style="color:rgba(255, 255, 255, 1); font-size: 1.8vh;margin-left:3vh;">视频名称：</div>
                                <input type="text" v-model="CharFormOne.VideoNameOne">
                            </div>
                        </div>
                        <!-- 上传 -->
                        <div class="RedUploading">
                            <!-- 上传视频/图片： -->
                            <div class="RedCharacters">
                                <!-- 图片 -->
                                <!-- <div class="RedPictureOne">上传图片：</div> -->
                                <!-- 图片 -->
                                <div class="RedPictureOne">上传视频：</div>
                            </div>
                            <!-- 图片 商品 -->
                            <div class="RedFlex">
                                <!-- 上传视频 -->
                                <div class="RedVideo" v-if="frequency">

                                    <el-upload action="http://ccsu.lzxx8848.com/api/tools/fileUpload/videoUpload"
                                        :limit="limitCountImgOne" class="dl-avatar-uploader-min square"
                                        :class="{ uoloadBtn: showBtnDealImgOne, disUoloadBtn: noneBtnImgOne }"
                                        :on-change="onChangeUploadOne" list-type="picture-card" :file-list="FileListOne"
                                        :headers="headers" :data="VideoDataOne" :on-preview="handlePreviewOne"
                                        :on-exceed="handleExceedCover" :before-upload="beforeImageUploadOne"
                                        :on-remove="coverFileRemoveOne" :on-success="coverFileSuccessOne">
                                        <div style="width:100%;height:100%;"> <img
                                                src="../../../assets/PersonalCenter/collect/uploading.png" alt=""
                                                style="width:100%;height:100%;"> </div>
                                    </el-upload>
                                    <el-dialog v-model="dialogVisibleOne">
                                        <video controls="controls" style="width: 100%;">
                                            <source :src="dialogImageUrlOne" type="video/mp4" />
                                        </video>

                                    </el-dialog>
                                </div>

                                <!-- 复制 -->
                                <div style="width:80%;height:100%;display: flex;justify-content: space-between;"
                                    v-if="CollInput">
                                    <!-- 第一个视频 -->
                                    <div style=" width:9vw;height:100%;cursor: pointer;">
                                        <video :src="DialogImageUrlTher"
                                            style="width:100%;height:100%;margin-top:-3vh;"></video>
                                    </div>
                                    <!-- 第二个视频 -->
                                    <div style=" width:9vw;height:100%;cursor: pointer;" v-if="CutVideoOne">
                                        <video :src="DialogImageUrlTwo"
                                            style="width:100%;height:100%;margin-top:-3vh;"></video>
                                    </div>
                                </div>
                                <!-- 视频 -->
                                <div style="width:80%;height:100%;display: flex;justify-content: space-between;" v-else>
                                    <!-- 第一个视频 -->
                                    <div style=" width:9vw;height:100%;cursor: pointer;" @click="cut()" v-if="CutVideo">
                                        <video :src="DialogImageUrlTher"
                                            style="width:100%;height:100%;margin-top:-3vh;"></video>
                                    </div>
                                    <!-- 第二个视频 -->
                                    <div style=" width:9vw;height:100%;cursor: pointer;" @click="CutOne()"
                                        v-if="CutVideoOne">
                                        <video :src="DialogImageUrlTwo"
                                            style="width:100%;height:100%;margin-top:-3vh;"></video>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <!-- 复制 -->
                        <div class="ChaVideoIntrodu" v-if="CollInput">
                            <div class="ChaTitle">视频简介1：</div>
                            <textarea v-model="VideoFormOne[0].VideoPoint" placeholder="请输入简介" class="RedCollText" readonly>

                            </textarea>
                            <div class="ChaTitle">视频简介2：</div>
                            <textarea v-model="VideoFormOne[1].VideoPoint" placeholder="请输入简介" class="RedCollText" readonly>

                            </textarea>
                        </div>
                        <!-- 视频简介 -->
                        <div class="ChaVideoIntrodu" v-else>
                            <div class="ChaTitle">视频简介1：</div>
                            <textarea v-model="VideoFormOne[0].VideoPoint" placeholder="请输入简介" class="RedCollText">

                            </textarea>
                            <div class="ChaTitle">视频简介2：</div>
                            <textarea v-model="VideoFormOne[1].VideoPoint" placeholder="请输入简介" class="RedCollText">

                            </textarea>
                        </div>
                        <!-- 校验 -->
                        <!-- <div style="font-size:1.7vh;margin-left:3vh;" v-if="CharScenic">所属景区不可以为空</div> -->
                        <!-- 输入 -->
                        <!-- <div class="RedTextBox">
                        <textarea v-model="CharForm.inputPoint" placeholder="请输入简介" class="RedCollText">

                                  </textarea>
                    </div> -->

                    </div>
                    <!-- 右边 -->
                    <div class="RedRight">
                        <!-- 复制 -->
                        <div class="RedTextBox" v-if="CollInput">
                            <div class="ChaTitle">人物简介：</div>
                            <textarea v-model="CharFormOne.inputPoint" placeholder="请输入简介" class="RedCollText" readonly>

                            </textarea>
                        </div>
                        <div class="RedTextBox" v-else>
                            <div class="ChaTitle">人物简介：</div>
                            <textarea v-model="CharFormOne.inputPoint" placeholder="请输入简介" class="RedCollText">

                            </textarea>
                        </div>

                        <!-- 上传 -->
                        <!-- 复制-->
                        <div class="CharFlexTwo" v-if="CollInput">
                            <!-- 图片名称-->
                            <div class="RedPicture">
                                <div style="color:rgba(255, 255, 255, 1); font-size: 1.8vh;margin-left:3vh;">图片名称：</div>
                                <input type="text" v-model="CharFormOne.ImgName" readonly="readonly">
                            </div>
                            <!-- 视频名称 -->
                            <div class="RedPicture">
                                <div style="color:rgba(255, 255, 255, 1); font-size: 1.8vh;margin-left:3vh;">图片名称：</div>
                                <input type="text" v-model="CharFormOne.ImgNameOne" readonly="readonly">
                            </div>
                        </div>
                        <!-- 图片，视频名称 -->
                        <div class="CharFlexTwo" v-else>
                            <!-- 图片名称-->
                            <div class="RedPicture">
                                <div style="color:rgba(255, 255, 255, 1); font-size: 1.8vh;margin-left:3vh;">图片名称：</div>
                                <input type="text" v-model="CharFormOne.ImgName">
                            </div>
                            <!-- 视频名称 -->
                            <div class="RedPicture">
                                <div style="color:rgba(255, 255, 255, 1); font-size: 1.8vh;margin-left:3vh;">图片名称：</div>
                                <input type="text" v-model="CharFormOne.ImgNameOne">
                            </div>
                        </div>
                        <div class="CharRight">
                            <div class="RedCharacters">
                                <!-- 图片 -->
                                <div class="RedPictureOne">上传图片：</div>
                            </div>
                            <!-- 上传图片 -->
                            <div class="RedRedPictureTwo">
                                <div class="RedVideo" v-if="FrequencyPicture">
                                    <el-upload action="http://ccsu.lzxx8848.com/api/tools/fileUpload/imgUpload"
                                        :limit="limitCountImg" class="dl-avatar-uploader-min square"
                                        :class="{ uoloadBtn: showBtnDealImg, disUoloadBtn: noneBtnImg }"
                                        :on-change="onChangeUpload" list-type="picture-card" :file-list="fileList"
                                        :headers="headers" :data="FormData" :on-preview="handlePreview"
                                        accept="image/jpeg,image/png,image/jpg" :on-exceed="handleExceedCover"
                                        :before-upload="beforeImageUpload" :on-remove="coverFileRemove"
                                        :on-success="coverFileSuccess">
                                        <div style="width:100%;height:100%;"> <img
                                                src="../../../assets/PersonalCenter/collect/uploading.png" alt=""
                                                style="width:100%;height:100%;"> </div>
                                    </el-upload>

                                    <el-dialog v-model="dialogVisible">
                                        <img w-full class="image onsite-img" :src="dialogImageUrl" />
                                    </el-dialog>
                                </div>
                                <!-- 复制 -->
                                <div style="width:80%;height:100%;display: flex;justify-content: space-between;"
                                    v-if="CollInput">
                                    <!-- 第一个图片 -->
                                    <div style=" width:11vh;height:11vh;cursor: pointer;">
                                        <img :src="PictureUrl" style="width:100%;height:100%;">
                                    </div>
                                    <!-- 第二个图片 -->
                                    <div style=" width:11vh;height:11vh;cursor: pointer;">
                                        <img :src="PictureUrlOne" style="width:100%;height:100%;">
                                    </div>
                                </div>
                                <!-- 图片 -->
                                <div style="width:80%;height:100%;display: flex;justify-content: space-between;" v-else>
                                    <!-- 第一个图片 -->
                                    <div style=" width:11vh;height:11vh;cursor: pointer;" @click="PictureCut()"
                                        v-if="CutPicture">
                                        <img :src="PictureUrl" style="width:100%;height:100%;">
                                    </div>
                                    <!-- 第二个图片 -->
                                    <div style=" width:11vh;height:11vh;cursor: pointer;" @click="PictureCutOne()"
                                        v-if="CutPictureOne">
                                        <img :src="PictureUrlOne" style="width:100%;height:100%;">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="ChaVideoIntrodu" v-if="CollInput">
                            <div class="ChaTitle">图片简介1：</div>
                            <textarea v-model="imgsOne[0].ImgsPoint" placeholder="请输入简介" class="RedCollText" readonly>

                            </textarea>
                            <div class="ChaTitle">图片简介2：</div>
                            <textarea v-model="imgsOne[1].ImgsPoint" placeholder="请输入简介" class="RedCollText" readonly>

                            </textarea>
                        </div>
                        <!-- 图片简介 -->
                        <div class="ChaVideoIntrodu" v-else>
                            <div class="ChaTitle">图片简介1：</div>
                            <textarea v-model="imgsOne[0].ImgsPoint" placeholder="请输入简介" class="RedCollText">

                            </textarea>
                            <div class="ChaTitle">图片简介2：</div>
                            <textarea v-model="imgsOne[1].ImgsPoint" placeholder="请输入简介" class="RedCollText">

                            </textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { log } from 'console';

export default {
    data() {
        return {
            // 红色人物点击是否可以编辑
            CollInput: true,
            // 红色人物切换视频
            CutVideo: true,
            frequency: false,
            CutVideoOne: true,
            // 红色人物切换图片
            FrequencyPicture: false,
            CutPicture: true,
            CutPictureOne: true,
            // 非物质文化遗产切换视频
            IntanVideo: true,
            IntanVideoOne: true,
            IntanShow: false,
            // 非物质文化遗产切换图片
            IntanShowTher: false,
            IntanShowOne: false,
            IntanPicture: true,
            IntanPictureOne: true,
            PictureUrlTwo: '',
            PictureUrlTher: '',
            //非物质文化遗产
            heritageId: '',
            ImmList: [],

            total: '',
            form: {
                // 每页记录数
                pageSize: 5,
                // 当前页数
                pageNum: 1,
            },
            // 红色文化
            CultureList: [],
            TotalOne: '',
            FormOne: {
                // 每页记录数
                pageSize: 5,
                // 当前页数
                pageNum: 1,
            },
            // 影像
            AreaList: [],
            TotalTwo: '',
            FormTwo: {
                // 每页记录数
                pageSize: 5,
                // 当前页数
                pageNum: 1,
            },
            EditIndexOne: true,
            intangible: true,
            editIndex: true,
            //征集列表
            collet: true,
            // 非遗征集编辑
            ColletOne: false,
            // 红色文化编辑
            ColletTwo: false,
            // 判断通过
            pass: 0,
            active: 0,
            NavList: ["非物质文化遗产", "红色人物", "红色影像"],
            count: 0,
            btnnum: 0,
            value: '',
            // 非物质文化遗产开始
            // 上传图片
            dialogImageUrl: '',
            fileUrl: [],
            coversList: [],
            limitCountImg: 2,
            DdZi: false,
            showBtnDealImg: true,
            noneBtnImg: false,
            dialogVisible: false,
            FormData: {   // 额外参数
                useType: 'ihInfo',
                imageFile: '',
            },
            headers: { Authorization: 'Bearer ' + this.$cookies.get("token") },
            //  上传视频
            // 视频上传
            // 测试
            FuFyiVideo: [],
            videoList: [],
            dialogImageUrlOne: '',
            limitCountImgOne: 2,
            showBtnDealImgOne: true,
            noneBtnImgOne: false,
            dialogVisibleOne: false,
            VideoData: {
                useType: 'ihInfo',
                videoFile: '',
            },
            CharForm: {
                // 非遗类别
                CollectionCat: '',
                name: '',
                //简介
                inputPoint: '',
                // 视频名称
                VideoName: '',
                VideoNameOne: '',
                // 图片名称
                ImgName: '',
                ImgNameOne: '',
                //出生地
                born: '',
                //所属景区
                scenic: '',
                //传承非文化物质遗产类型：
                type: '',
            },
            videoForm: [
                {
                    // 视频简介
                    VideoPoint: '',
                },
                {
                    // 视频简介
                    VideoPoint: '',
                }
            ],

            CharScenic: '',
            // 查景区
            // ScenicArea: [],
            // id: '',
            // // scenicSpot:[],
            // value: "",
            // 遍历的视频集
            videos: [
                {
                    title: '',
                    resourceUrl: '',
                    summary: '',
                }
                ,
                {
                    title: '',
                    resourceUrl: '',
                    summary: '',
                }
            ],
            VideosOne: [
                {
                    title: '',
                    resourceUrl: '',
                    summary: '',
                }
                ,
                {
                    title: '',
                    resourceUrl: '',
                    summary: '',
                }
            ],
            imgs: [
                {
                    title: '',
                    resourceUrl: '',
                    summary: '',
                }
                ,
                {
                    title: '',
                    resourceUrl: '',
                    summary: '',
                }
            ],
            imgss: [
                {
                    title: '',
                    resourceUrl: '',
                    summary: '',
                }
                ,
                {
                    title: '',
                    resourceUrl: '',
                    summary: '',
                }
            ],
            videoOne: [],
            // 遍历的图片集
            IamgeOne: [],
            imgsTwo: [
                {
                    ImgsPoint: '',
                },
                {
                    ImgsPoint: '',
                }
            ],
            // 非物质文化遗产结束
            // 红色文化开始
            // 红色人物提交修改
            ImgsOne: [
                {
                    title: '',
                    resourceUrl: '',
                    summary: '',
                }
                ,
                {
                    title: '',
                    resourceUrl: '',
                    summary: '',
                }
            ],
            // 红色文化id
            FigureId: '',
            // 图片
            PictureUrl: '',
            PictureUrlOne: '',
            // 上传图片
            dialogImageUrl: '',
            coversList: [],
            limitCountImg: 2,
            showBtnDealImg: true,
            noneBtnImg: false,
            dialogVisible: false,
            FormData: {   // 额外参数
                useType: 'personage',
                imageFile: '',
            },
            headers: { Authorization: 'Bearer ' + this.$cookies.get("token") },
            //  上传视频
            // 视频
            DialogImageUrlTher: '',
            DialogImageUrlTwo: '',
            // 视频上传
            videoList: [],
            videoListOne: '',
            videoListTwo: '',
            dialogImageUrlOne: '',
            limitCountImgOne: 2,
            showBtnDealImgOne: true,
            noneBtnImgOne: false,
            dialogVisibleOne: false,
            VideoDataOne: {
                useType: 'personage',
                videoFile: '',
            },
            CharFormOne: {
                // 上传的视频
                UploadVideo: '',
                //名称
                NameOne: '',
                //简介
                inputPoint: '',
                // 视频名称
                VideoName: '',
                VideoNameOne: '',
                // 图片名称
                ImgName: '',
                ImgNameOne: '',
                //出生的
                // born: '',
                // 出生时间   
                // time: '',
                //所属景区
                scenic: '',
            },
            VideoFormOne: [
                {
                    // 视频简介
                    VideoPoint: '',
                },
                {
                    // 视频简介
                    VideoPoint: '',
                }
            ],

            CharScenic: '',
            options: [
                {
                    value: 1,
                    label: "红色人物",
                },
                {
                    value: 2,
                    label: "红色影像",
                },
            ],
            // 查景区
            ScenicArea: [],
            id: '',
            // scenicSpot:[],
            value: "",
            videoOne: [],
            // 遍历的图片集
            IamgeTwo: [],
            imgsOne: [
                {
                    ImgsPoint: '',
                },
                {
                    ImgsPoint: '',
                }
            ],

            // 红色文化结束
            // 非遗类别
            CollectionCategoryOne: [],
            // 类别id
            categoryId: '',
            IdOne: '',
            // 查省
            province: [],
            ShengId: '',
            // 查市
            cityarr: [],
            ShiId: '',
            // 查城市
            regionarr: [],
            DistrictOne: '',
            ruleForm: {
                // 省份
                Province: '',
                // 市
                cityValue: '',
                // 县
                RegionValue: '',
            },

        }
    },
    created() {
        this.ImmListOne()
        this.CulListOne()
        this.ArchitOne()
        // 非物质文化遗产查园区
        this.FindScenic()
        // 查类别
        this.CollectionCategory()
        this.sheng()
    },
    method() {
        // 非物质文化遗产
        this.$refs.PersonDom.addEventListener('scroll', this.CollectScroll, true);
        // 红色文化
        this.$refs.PersonDomOne.addEventListener('scroll', this.CultureScroll, true);
        // 建筑
        this.$refs.PersonDomTwo.addEventListener('scroll', this.ArchiteScroll, true);

    },
    destroyed() {
        //页面离开后销毁，防止切换路由后上一个页面监听scroll滚动事件会在新页面报错问题 
        // 非物质文化遗产
        this.$refs.PersonDom.addEventListener('scroll', this.CollectScroll, true);
        // 红色文化
        this.$refs.PersonDomOne.addEventListener('scroll', this.CultureScroll, true);
        // 建筑
        this.$refs.PersonDomTwo.addEventListener('scroll', this.ArchiteScroll, true);

    },
    methods: {
        // 点击切换红色人物视频
        cut() {
            this.CutVideo = false;
            this.frequency = true;
        },
        CutOne() {
            this.CutVideoOne = false;
            this.frequency = true;
        },
        // 点击切换红色人物图片
        PictureCut() {
            this.FrequencyPicture = true;
            this.CutPicture = false;
        },
        PictureCutOne() {
            this.FrequencyPicture = true;
            this.CutPictureOne = false;
        },
        /*切换需要高亮的按钮*/
        change(index) {
            this.active = index;
            this.count = index;
            this.btnnum = index;
        },
        // 非遗点击可以编辑
        edit() {
            this.EditIndexOne = false
            this.intangible = false
            this.IntanPictureOne = true
            this.IntanPicture = true
            this.IntanVideo = true;
            this.IntanVideoOne = true;
            this.DdZi = true;
        },
        //非遗点击取消编辑
        cancel() {
            this.EditIndexOne = true
            this.collet = true;
            this.ColletOne = false;
            this.intangible = true;
            this.IntanShow = false;
            this.IntanShowTher = false;
            this.IntanVideo = true;
            this.IntanVideoOne = true;
            this.IntanPicture = true;
            this.IntanPictureOne = true;
        },
        // 非遗点击切换视频
        intan() {
            this.IntanVideo = false;
            this.IntanShow = true;
        },
        IntanOne() {
            this.IntanVideoOne = false;
            this.IntanShow = true;
        },
        // 非遗点击切换图片
        PictureIntan() {
            this.IntanShowTher = true;
            this.IntanPicture = false;
        },
        PictureIntanOne() {
            this.IntanShowTher = true;
            this.IntanPictureOne = false;
        },
        //非物质文化遗产点击出现编辑页面
        list(heritageId, scenicId, categoryId) {
            this.heritageId = heritageId
            this.id = scenicId
            this.categoryId = categoryId
            this.collet = false;
            this.ColletOne = true;
            this.IntanVideo = true;
            this.IntanVideoOne = true;
            this.IntanShow = false;
            this.IntanShowTher = false;
            this.DdZi = false;
            var axios = require('axios');
            var config = {
                method: 'get',
                url: this.$Schttp + '/vtp/app/ihInfo/detail/' + heritageId,
                headers: {
                    Authorization: "Bearer " + this.$cookies.get("token"),
                    "Content-Type": "application/json",
                },
                data: {
                }
            };
            axios(config)
                .then((res) => {
                    if (res.data.code == 200) {
                        this.CharForm.name = res.data.data.ihInfo.title
                        this.CharForm.CollectionCat = res.data.data.ihInfo.categoryName
                        this.CharForm.scenic = res.data.data.ihInfo.scenicName
                        this.ruleForm.province = res.data.data.ihInfo.provinceName
                        this.ruleForm.cityValue = res.data.data.ihInfo.cityName
                        this.ruleForm.RegionValue = res.data.data.ihInfo.districtName
                        this.CharForm.VideoName = res.data.data.ihInfo.videos[0].title
                        this.CharForm.VideoNameOne = res.data.data.ihInfo.videos[1].title
                        this.videoListOne = res.data.data.ihInfo.videos[0].resourceUrl
                        this.videoListTwo = res.data.data.ihInfo.videos[1].resourceUrl
                        this.videoForm[0].VideoPoint = res.data.data.ihInfo.videos[0].summary
                        this.videoForm[1].VideoPoint = res.data.data.ihInfo.videos[1].summary
                        this.CharForm.inputPoint = res.data.data.ihInfo.summary
                        this.CharForm.ImgName = res.data.data.ihInfo.imgs[0].title
                        this.CharForm.ImgNameOne = res.data.data.ihInfo.imgs[1].title
                        this.PictureUrlTwo = res.data.data.ihInfo.imgs[0].resourceUrl
                        this.PictureUrlTher = res.data.data.ihInfo.imgs[1].resourceUrl
                        this.imgsTwo[0].ImgsPoint = res.data.data.ihInfo.imgs[0].summary
                        this.imgsTwo[1].ImgsPoint = res.data.data.ihInfo.imgs[1].summary
                    }
                })
                .catch(function (error) {
                });
        },
        // 查类别
        CollectionCategory() {
            var axios = require('axios');
            var config = {
                method: 'get',
                url: this.$Schttp + '/vtp/app/ihInfo/getIhCategoryList',
                headers: {
                    Authorization: "Bearer " + this.$cookies.get("token"),
                    "Content-Type": "application/json",
                },
            };
            axios(config)
                .then((res) => {
                    if (res.data.code == 200) {
                        this.CollectionCategoryOne = res.data.data
                    }
                })
                .catch(function (error) {
                });
        },
        // 非遗类别id
        CollectionCateg(categoryId) {
            this.categoryId = categoryId
        },
        //红色文化点击取消编辑
        CancelOne() {
            this.editIndex = true;
            this.collet = true;
            this.ColletTwo = false;
            this.CollInput = true;
            this.frequency = false;
            this.FrequencyPicture = false;
        },
        // 红色文化点击可以编辑
        EditOne() {
            this.editIndex = false;
            this.CollInput = false;
        },
        //红色文化点击出现编辑页面
        ListOne(FigureId, scenicId) {
            this.FigureId = FigureId
            this.id = scenicId
            this.collet = false;
            this.CollInput = true
            this.ColletTwo = true;
            this.editIndex = true;
            this.CutPictureOne = true;
            this.CutPicture = true;
            this.CutVideoOne = true;
            this.CutVideoTwo = true;
            this.frequency = false;
            this.FrequencyPicture = false;
            var axios = require('axios');
            var config = {
                method: 'get',
                url: this.$Schttp + '/vtp/app/personage/detail/' + FigureId,
                headers: {
                    Authorization: "Bearer " + this.$cookies.get("token"),
                    "Content-Type": "application/json",
                },
                data: {
                }
            };
            axios(config)
                .then((res) => {
                    if (res.data.code == 200) {
                        this.CharFormOne.NameOne = res.data.data.title
                        this.CharFormOne.scenic = res.data.data.scenicName
                        this.CharFormOne.inputPoint = res.data.data.summary
                        this.CharFormOne.ImgName = res.data.data.imgs[0].title
                        this.CharFormOne.ImgNameOne = res.data.data.imgs[1].title
                        this.PictureUrl = res.data.data.imgs[0].resourceUrl
                        this.PictureUrlOne = res.data.data.imgs[1].resourceUrl
                        this.imgsOne[0].ImgsPoint = res.data.data.imgs[0].summary
                        this.imgsOne[1].ImgsPoint = res.data.data.imgs[1].summary
                        this.CharFormOne.VideoName = res.data.data.videos[0].title
                        this.CharFormOne.VideoNameOne = res.data.data.videos[1].title
                        this.DialogImageUrlTher = res.data.data.videos[0].resourceUrl
                        this.DialogImageUrlTwo = res.data.data.videos[1].resourceUrl
                        this.VideoFormOne[0].VideoPoint = res.data.data.videos[0].summary
                        this.VideoFormOne[1].VideoPoint = res.data.data.videos[1].summary
                    }
                })
                .catch(function (error) {
                });
        },
        // 非物质文化遗产数据更新
        ImmListTwo() {
            var axios = require('axios');
            var config = {
                method: 'post',
                url: this.$Schttp + '/vtp/app/ihInfo/myList',
                headers: {
                    Authorization: "Bearer " + this.$cookies.get("token"),
                    "Content-Type": "application/json",
                },
                data: {

                }
            };
            axios(config)
                .then((res) => {
                    if (res.data.code == 200) {
                        this.ImmList = res.data.rows
                    }
                })
                .catch(function (error) {
                });

        },
        //非物质文化遗产
        ImmListOne() {
            var axios = require("axios");
            const data = {
                pageSize: this.form.pageSize,
                pageNum: this.form.pageNum,

            };
            var config = {
                method: "post",
                url: this.$Schttp + "/vtp/app/ihInfo/myList",
                headers: {
                    Authorization: "Bearer " + this.$cookies.get("token"),
                    "Content-Type": "application/json",
                },
                data: data,
            };

            axios(config).then((res) => {

                if (res.data.code == 200) {
                    this.ImmList.push(...res.data.rows);
                    this.total = res.data.total;
                }
            });
        },
        CollectScroll(e) {
            let h = e.target.offsetHeight + e.target.scrollTop - e.target.scrollHeight
            if (h > -4) {

                if (this.form.pageNum * this.form.pageSize >= this.total) {
                } else {
                    if (this.form.pageNum <= this.form.pageNum - 1) {

                    } else {
                        this.form.pageNum++
                        this.ImmListOne()

                    }
                }

            }
        },
        // 查省  
        sheng() {
            var axios = require('axios');
            var config = {
                method: 'get',
                url: this.$Schttp + '/getProvinceList',
                headers: {
                    Authorization: "Bearer " + this.$cookies.get("token"),
                    "Content-Type": "application/json",
                },
            };
            axios(config)
                .then((res) => {
                    if (res.data.code == 200) {
                        this.province = res.data.data

                    }
                })
                .catch(function (error) {
                });

        },
        // 查市
        selectProvince(id) {
            // 省的id 
            this.ShengId = id;
            var axios = require('axios');
            var config = {
                method: 'get',
                url: this.$Schttp + '/getCityList/' + id,
                headers: {
                    Authorization: "Bearer " + this.$cookies.get("token"),
                    "Content-Type": "application/json",
                },
                data: {
                    parentId: id,
                }
            };
            axios(config)
                .then((res) => {
                    if (res.data.code == 200) {
                        this.cityarr = res.data.data
                    }
                })
                .catch(function (error) {
                });
        },
        // 查城市
        selectCountry(id) {
            // 市的id 
            this.ShiId = id;
            var axios = require('axios');
            var config = {
                method: 'get',
                url: this.$Schttp + '/getDistrictList/' + id,
                headers: {
                    Authorization: "Bearer " + this.$cookies.get("token"),
                    "Content-Type": "application/json",
                },
                data: {
                    parentId: id,
                }
            };
            axios(config)
                .then((res) => {
                    if (res.data.code == 200) {
                        this.regionarr = res.data.data
                    }
                })
                .catch(function (error) {
                });
        },
        // 查区
        district(id) {
            this.DistrictOne = id
        },
        // 红色文化数据更新
        CulListTwo() {
            var axios = require('axios');
            var config = {
                method: 'post',
                url: this.$Schttp + '/vtp/app/personage/myList',
                headers: {
                    Authorization: "Bearer " + this.$cookies.get("token"),
                    "Content-Type": "application/json",
                },
                data: {

                }
            };
            axios(config)
                .then((res) => {
                    if (res.data.code == 200) {
                        this.CultureList = res.data.rows
                    }
                })
                .catch(function (error) {
                });

        },
        //红色文化
        CulListOne() {
            var axios = require("axios");
            const data = {
                pageSize: this.FormOne.pageSize,
                pageNum: this.FormOne.pageNum,

            };
            var config = {
                method: "post",
                url: this.$Schttp + "/vtp/app/personage/myList",
                headers: {
                    Authorization: "Bearer " + this.$cookies.get("token"),
                    "Content-Type": "application/json",
                },
                data: data,
            };

            axios(config).then((res) => {

                if (res.data.code == 200) {
                    const Cul = res.data.rows;
                    this.CultureList.push(...Cul);
                    this.TotalOne = res.data.total;

                }
            });
        },
        CultureScroll(e) {
            let h = e.target.offsetHeight + e.target.scrollTop - e.target.scrollHeight
            if (h > -4) {

                if (this.FormOne.pageNum * this.FormOne.pageSize >= this.TotalOne) {
                } else {
                    this.FormOne.pageNum++
                    this.CulListOne()
                }

            }
        },
        //红色影像
        ArchitOne() {
            var axios = require("axios");
            const data = {
                pageSize: this.FormTwo.pageSize,
                pageNum: this.FormTwo.pageNum,

            };
            var config = {
                method: "post",
                url: this.$Schttp + "/vtp/app/redCultureVideo/myList",
                headers: {
                    Authorization: "Bearer " + this.$cookies.get("token"),
                    "Content-Type": "application/json",
                },
                data: data,
            };

            axios(config).then((res) => {

                if (res.data.code == 200) {
                    const Are = res.data.rows;
                    this.AreaList.push(...Are);
                    this.TotalTwo = res.data.total;

                }
            });
        },
        ArchiteScroll(e) {
            let h = e.target.offsetHeight + e.target.scrollTop - e.target.scrollHeight
            if (h > -4) {

                if (this.FormTwo.pageNum * this.FormTwo.pageSize >= this.TotalTwo) {
                } else {
                    if (this.Form.pageNum <= this.Form.pageNum - 1) {

                    } else {

                        this.FormTwo.pageNum++
                        this.ArchitOne()

                    }
                }

            }
        },
        // 非物质文化遗产开始
        // 图片上传
        onChangeUploadTher(file, fileList) {
            this.FormData.imageFile = file.raw
            this.noneBtnImg = fileList.length >= this.limitCountImg;
        },
        beforeImageUploadTher(rawFile) {
            if (rawFile.size / 1024 / 1024 > 2) {
                this.$message.error("单张图片大小不能超过2MB!");
                return false;
            }
            return true;
        },
        coverFileSuccessTher(response, file, fileList) {
            if (response.code === 200) {
                let obj = {};
                obj.name = file.name;
                obj.url = response.data;
                this.coversList.push(obj);
                this.fileUrl.push(obj.url.fileUrl)
                this.PictureUrlTwo = this.fileUrl[0]
                this.PictureUrlTher = this.fileUrl[1]
            } else {
                this.$message.error(`图片${response.msg}上传失败`)
            }
        },


        coverFileRemoveTher(file, fileList) {
            this.coversList.forEach((item, index) => {
                if (file.name == item.name) {
                    this.coversList.splice(index, 1);
                }
            });
            this.noneBtnImg = fileList.length >= this.limitCountImg;
        },
        handleExceedCoverTher(files, fileList) {
            this.$message.error({
                message: `上传图片数量超出限制！`,
                type: "error",
            });
        },
        handlePreviewTher(uploadFile) {
            this.dialogImageUrl = uploadFile.url;
            this.dialogVisible = true;

        },
        // 上传视频
        //上传前回调 
        // 上传视频
        onChangeUploadTwo(file, FileListOne) {
            this.VideoData.videoFile = file.raw
            this.noneBtnImgOne = FileListOne.length >= this.limitCountImgOne;
        },
        beforeImageUploadTwo(rawFile,) {
            if (rawFile.size / 1024 / 1024 > 30) {
                this.$message.error("单个视频大小不能超过30MB!");
                return false;
            }
            if ([
                "video/mp4",
                "video/ogg",
                "video/flv",
                "video/avi",
                "video/wmv",
                "video/rmvb",
                "video/mov",
            ].indexOf(rawFile.type) == -1     //控制格式
            ) {
                this.$message.error("请上传正确的视频格式!");
                return false;
            }
            return true;
        },
        coverFileSuccessTwo(response, file, FileListOne) {

            if (response.code === 200) {
                let obj = {};
                obj.name = file.name;
                obj.url = response.data;
                this.videoList.push(obj);
                this.FuFyiVideo.push(obj.url.fileUrl)
                this.videoListOne = this.FuFyiVideo[0];
                this.videoListTwo = this.FuFyiVideo[1];

                this.$message({
                    message: '上传成功',
                    type: 'success'
                });
            } else {
                this.$message.error(`视频上传失败`)
            }
            if (response.code === 401) {
                this.$message({
                    message: '请先登录',
                    type: 'warning'
                });
                this.$router.push('/login');
            }
        },


        coverFileRemoveTwo(file, FileListOne) {
            this.videoList.forEach((item, index) => {
                if (file.name == item.name) {
                    this.videoList.splice(index, 1);
                }
            });
            this.noneBtnImgOne = FileListOne.length >= this.limitCountImgOne;
        },
        handleExceedCoverOne(files, FileListOne) {
            this.$message.error({
                message: `上传视频数量超出限制！`,
                type: "error",
            });
        },
        handlePreviewTwo(uploadFile) {
            this.dialogImageUrlOne = uploadFile.url;
            this.dialogVisibleOne = true;
        },
        // 上传视频
        onChangeUploadOne(file, FileListOne) {
            this.VideoDataOne.videoFile = file.raw
            this.noneBtnImgOne = FileListOne.length >= this.limitCountImgOne;
        },
        beforeImageUploadOne(rawFile,) {
            if (rawFile.size / 1024 / 1024 > 30) {
                this.$message.error("单个视频大小不能超过30MB!");
                return false;
            }
            if ([
                "video/mp4",
                "video/ogg",
                "video/flv",
                "video/avi",
                "video/wmv",
                "video/rmvb",
                "video/mov",
            ].indexOf(rawFile.type) == -1     //控制格式
            ) {
                this.$message.error("请上传正确的视频格式!");
                return false;
            }
            return true;
        },
        coverFileSuccessOne(response, file, FileListOne) {

            if (response.code === 200) {
                let obj = {};
                obj.name = file.name;
                obj.url = response.data;
                this.videoList.push(obj);
                this.videoOne.push(obj.url.fileUrl)
                this.DialogImageUrlTher = this.videoOne[0]
                this.DialogImageUrlTwo = this.videoOne[1]
                this.$message({
                    message: '上传成功',
                    type: 'success'
                });
            } else {
                this.$message.error(`视频上传失败`)
            }
            if (response.code === 401) {
                this.$message({
                    message: '请先登录',
                    type: 'warning'
                });
                this.$router.push('/login');
            }
        },


        coverFileRemoveOne(file, FileListOne) {
            this.videoList.forEach((item, index) => {
                if (file.name == item.name) {
                    this.videoList.splice(index, 1);
                }
            });
            this.noneBtnImgOne = FileListOne.length >= this.limitCountImgOne;
        },
        handleExceedCoverOne(files, FileListOne) {
            this.$message.error({
                message: `上传视频数量超出限制！`,
                type: "error",
            });
        },
        handlePreviewOne(uploadFile) {
            this.dialogImageUrlOne = uploadFile.url;
            this.dialogVisibleOne = true;
        },
        // 查所以景区.
        FindScenic() {
            var axios = require('axios');
            var config = {
                method: 'get',
                url: this.$Schttp + '/vtp/app/scenic/scenicIndex',
                headers: {
                    Authorization: "Bearer " + this.$cookies.get("token"),
                    "Content-Type": "application/json",
                },
            };
            axios(config)
                .then((res) => {
                    if (res.data.code == 200) {
                        this.ScenicArea = res.data.data
                    }
                })
                .catch(function (error) {
                });
        },
        // 点击查id
        ScenicId(id) {
            this.id = id
        },
        // 非遗点击提交
        save() {
            // 图片集
            this.imgs[0].title = this.CharForm.ImgName
            this.imgs[1].title = this.CharForm.ImgNameOne
            this.imgs[0].resourceUrl = this.PictureUrlTwo
            this.imgs[1].resourceUrl = this.PictureUrlTher
            this.imgs[0].summary = this.imgsTwo[0].ImgsPoint
            this.imgs[1].summary = this.imgsTwo[1].ImgsPoint
            // 点击上传的时候
            this.videos[0].title = this.CharForm.VideoName
            this.videos[1].title = this.CharForm.VideoNameOne
            this.videos[0].resourceUrl = this.videoListOne
            this.videos[1].resourceUrl = this.videoListTwo
            this.videos[0].summary = this.videoForm[0].VideoPoint
            this.videos[1].summary = this.videoForm[1].VideoPoint
            var axios = require('axios');
            var config = {
                method: 'PUT',
                url: this.$Schttp + '/vtp/app/ihInfo/update',
                headers: {
                    Authorization: "Bearer " + this.$cookies.get("token"),
                    "Content-Type": "application/json",
                },
                data: {
                    heritageId: this.heritageId,
                    scenicId: this.id,
                    provinceId: this.ShengId,
                    cityId: this.ShiId,
                    districtId: this.DistrictOne,
                    categoryId: this.categoryId,
                    title: this.CharForm.name,
                    summary: this.CharForm.inputPoint,
                    imgs: this.imgs,
                    videos: this.videos,
                }
            };
            axios(config)
                .then((res) => {
                    if (res.data.code == 200) {
                        this.$message({
                            message: '提交成功',
                            type: 'success'
                        });
                        // 非遗编辑字体出现
                        this.EditIndexOne = true;
                        // 非遗不可以编辑
                        this.intangible = true;
                        // 非遗列表页面
                        this.collet = true;
                        // 非遗编辑页面
                        this.ColletOne = false;
                        this.ImmListTwo()

                    }
                })
                .catch(function (error) {
                });

        },
        // 非物质文化遗产结束
        // 红色人物开始
        // 红色人物修改提交
        SaveOne() {
            // 视频集
            this.VideosOne[0].title = this.CharFormOne.VideoName
            this.VideosOne[1].title = this.CharFormOne.VideoNameOne
            this.VideosOne[0].resourceUrl = this.DialogImageUrlTher
            this.VideosOne[1].resourceUrl = this.DialogImageUrlTwo
            this.VideosOne[0].summary = this.VideoFormOne[0].VideoPoint
            this.VideosOne[1].summary = this.VideoFormOne[1].VideoPoint
            // 图片集
            this.ImgsOne[0].title = this.CharFormOne.ImgName
            this.ImgsOne[1].title = this.CharFormOne.ImgNameOne
            this.ImgsOne[0].resourceUrl = this.PictureUrl
            this.ImgsOne[1].resourceUrl = this.PictureUrlOne
            this.ImgsOne[0].summary = this.imgsOne[0].ImgsPoint
            this.ImgsOne[1].summary = this.imgsOne[1].ImgsPoint
            var axios = require('axios');
            var config = {
                method: 'PUT',
                url: this.$Schttp + '/vtp/app/personage/update',
                headers: {
                    Authorization: "Bearer " + this.$cookies.get("token"),
                    "Content-Type": "application/json",
                },
                data: {
                    personageId: this.FigureId,
                    scenicId: this.id,
                    title: this.CharFormOne.NameOne,
                    summary: this.CharFormOne.inputPoint,
                    imgs: this.ImgsOne,
                    videos: this.VideosOne,
                }
            };
            axios(config)
                .then((res) => {
                    if (res.data.code == 200) {
                        this.$message({
                            message: '提交成功',
                            type: 'success'
                        });
                        this.ColletTwo = false,
                            this.CollInput = false;
                        this.collet = true;
                        this.editIndex = false,
                            this.CulListTwo()
                    }
                    if (res.data.code == 500) {
                        this.$message({
                            message: '提交失败',
                            type: 'error'
                        });

                    }

                })
                .catch(function (error) {
                });

        },
        //上传图片
        onChangeUpload(file, fileList) {
            this.FormData.imageFile = file.raw
            this.noneBtnImg = fileList.length >= this.limitCountImg;
        },
        beforeImageUpload(rawFile) {
            if (rawFile.size / 1024 / 1024 > 2) {
                this.$message.error("单张图片大小不能超过2MB!");
                return false;
            }
            return true;
        },
        coverFileSuccess(response, file, fileList) {
            if (response.code === 200) {
                let obj = {};
                obj.name = file.name;
                obj.url = response.data;
                this.coversList.push(obj);
                this.IamgeTwo.push(obj.url.fileUrl)
                this.PictureUrl = this.IamgeTwo[0]
                this.PictureUrlOne = this.IamgeTwo[1]
                this.$message({
                    message: '上传成功',
                    type: 'success'
                });
            } else {
                this.$message.error(`图片${response.msg}上传失败`)
            }
            if (response.code === 401) {
                this.$message({
                    message: '请先登录',
                    type: 'warning'
                });
                this.$router.push('/login');
            }
        },
        coverFileRemove(file, fileList) {
            this.coversList.forEach((item, index) => {
                if (file.name == item.name) {
                    this.coversList.splice(index, 1);
                }
            });
            this.noneBtnImg = fileList.length >= this.limitCountImg;
        },
        handleExceedCover(files, fileList) {
            this.$message.error({
                message: `上传图片数量超出限制！`,
                type: "error",
            });
        },
        handlePreview(uploadFile) {
            this.dialogImageUrl = uploadFile.url;
            this.dialogVisible = true;
        },
        // 上传视频
        onChangeUploadOne(file, FileListOne) {
            this.VideoDataOne.videoFile = file.raw
            this.noneBtnImgOne = FileListOne.length >= this.limitCountImgOne;
        },
        beforeImageUploadOne(rawFile,) {
            if (rawFile.size / 1024 / 1024 > 30) {
                this.$message.error("单个视频大小不能超过30MB!");
                return false;
            }
            if ([
                "video/mp4",
                "video/ogg",
                "video/flv",
                "video/avi",
                "video/wmv",
                "video/rmvb",
                "video/mov",
            ].indexOf(rawFile.type) == -1     //控制格式
            ) {
                this.$message.error("请上传正确的视频格式!");
                return false;
            }
            return true;
        },
        coverFileSuccessOne(response, file, FileListOne) {

            if (response.code === 200) {
                let obj = {};
                obj.name = file.name;
                obj.url = response.data;
                this.videoList.push(obj);
                this.videoOne.push(obj.url.fileUrl)
                if (this.noneBtnImgOne === true) {
                    this.CutVideo = false
                    this.CutVideoOne = false
                }

                this.$message({
                    message: '上传成功',
                    type: 'success'
                });
            } else {
                this.$message.error(`视频上传失败`)
            }
            if (response.code === 401) {
                this.$message({
                    message: '请先登录',
                    type: 'warning'
                });
                this.$router.push('/login');
            }
        },


        coverFileRemoveOne(file, FileListOne) {
            this.videoList.forEach((item, index) => {
                if (file.name == item.name) {
                    this.videoList.splice(index, 1);
                }
            });
            this.noneBtnImgOne = FileListOne.length >= this.limitCountImgOne;
        },
        handleExceedCoverOne(files, FileListOne) {
            this.$message.error({
                message: `上传视频数量超出限制！`,
                type: "error",
            });
        },
        handlePreviewOne(uploadFile) {
            this.dialogImageUrlOne = uploadFile.url;
            this.dialogVisibleOne = true;
        },
        // 红色文化结束
    }
}
</script>

<style lang="less" scoped>
* {
    padding: 0;
    margin: 0;
}

.CollMax {
    width: 99%;
    height: 95%;
    display: flex;
    justify-content: center;

    .CollBox {
        width: 94%;
        height: 99%;

        .Law-Tab {
            width: 50%;
            margin-top: 2vh;

            .Law-Tab-Nav {
                width: 100%;
                display: flex;
                font-size: 2vh;
                display: flex;
                justify-content: space-between;

                .li {
                    width: 25%;
                    height: 6vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 0.5vh;
                    background-image: url(../../../assets/PersonalCenter/collect/box.png);
                    background-size: 100% 100%;
                }

                .btna {
                    background-image: url(../../../assets/PersonalCenter/collect/BoxOne.png);
                    background-size: 100% 100%;
                }
            }

            .actived {
                color: rgba(205, 41, 41, 1);
            }

        }

        .end-cont {
            display: none;
            width: 100%;
            height: 69vh;

            .CollTbe {
                width: 100%;
                height: 8vh;
                border-bottom: 1px dashed rgba(255, 255, 255, 1);
                display: flex;
                margin-top: 3vh;

                .CollTitle {
                    width: 25%;
                    height: 100%;
                    color: rgba(255, 255, 255, 1);
                    font-size: 2vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .CollTitleOne {
                    width: 10%;
                    height: 100%;
                    color: rgba(255, 255, 255, 1);
                    font-size: 2vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: 15.68vh;
                }
            }

            .CollContent {
                width: 100%;
                height: 10vh;
                display: flex;
                border-bottom: 1px dashed rgba(255, 255, 255, 1);
                cursor: pointer;

                .CollTd {
                    width: 25%;
                    height: 100%;
                    color: rgba(255, 255, 255, 1);
                    font-size: 2vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .CollTdOne {
                    width: 10%;
                    height: 100%;
                    color: rgba(255, 255, 255, 1);
                    font-size: 2vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: 16.1vh;
                }
            }
        }

        /* 整个滚动条 */
        ::-webkit-scrollbar {
            width: 0.3vw;
            height: 0.1vh;
            background-color: rgba(125, 151, 241, 1);
        }

        /* 滚动条上的滚动滑块 */
        ::-webkit-scrollbar-thumb {
            height: 0.3vh;
            background-color: rgba(41, 98, 182, 1);
            border-radius: 32px;
        }

        .dis {
            display: block;

        }
    }

    .CollBoxOne {
        width: 94%;
        height: 99%;

        .CollBoxTwo {
            width: 100%;
            height: 50%;
            margin-top: 4vh;
            display: flex;
            justify-content: space-between;
            flex-shrink: 0;

            .CollInput {
                width: 50%;
                height: 100%;

                .CollName {
                    width: 100%;
                    height: 6vh;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 1px solid rgba(100, 162, 255, 1);

                    .CollNameOne {
                        font-size: 2vh;
                        margin-left: 2vh;
                    }

                    .CollContentOne {
                        width: 65%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        color: rgba(255, 255, 255, 1);
                        font-size: 2.2vh;
                        font-weight: 300;
                    }

                    .CollinputOne {
                        width: 65%;
                        height: 100%;

                        input {
                            width: 100%;
                            height: 100%;
                            border: none;
                            background-color: rgba(255, 255, 255, 0);
                            color: rgba(255, 255, 255, 1);
                            font-size: 2.2vh;
                            font-weight: 300;
                            outline: none;
                        }

                        input::-webkit-input-placeholder {
                            color: rgba(255, 255, 255, 1);
                        }

                        // 修改input默认值颜色 兼容其它主流浏览器
                        /deep/ input::-webkit-input-placeholder {
                            color: rgba(255, 255, 255, 0.50);
                        }

                        /deep/ input::-moz-input-placeholder {
                            color: rgba(255, 255, 255, 0.50);
                        }

                        /deep/ input::-ms-input-placeholder {
                            color: rgba(255, 255, 255, 0.50);
                        }

                        // input框
                        /deep/ .el-select,
                        /deep/ .el-input,
                        /deep/ .el-input__inner {
                            width: 100%;
                            background-color: rgba(255, 255, 255, 0);
                            color: rgba(255, 255, 255, 1);
                            border: none; // 去掉边框
                            font-size: 2.2vh;
                            font-weight: 300;
                        }

                        /deep/ .el-select .el-input .el-select__caret {
                            color: rgba(100, 162, 255, 1);
                        }

                        // option选项 最外层
                        /deep/ .el-select-dropdown {
                            border: none !important;
                            background: rgba(255, 255, 255, 1) !important;
                            z-index: 9999;
                        }



                    }
                }

            }

            .CollUploadBox {
                width: 45%;
                height: 100%;
                display: flex;
                justify-content: space-between;

                .CollPload {
                    width: 75%;
                    display: flex;
                    justify-content: space-between;

                    .CollUploadBoxOne {
                        width: 45%;
                        height: 20vh;
                    }
                }
            }
        }

        .CollBoxThere {
            width: 100%;
            height: 32.5vh;
            background-image: url(../../../assets/PersonalCenter/collect/textbox.png);
            background-size: 100% 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .CollBTextbox {
                width: 96%;
                height: 80%;
                display: flex;
                align-items: center;
                flex-direction: column;

                .CollTitleFive {
                    width: 100%;
                    color: rgba(255, 255, 255, 1);
                    font-size: 2vh;
                }

                .CollContentFxe {
                    width: 99%;
                    height: 81.3%;
                    margin-top: 2vh;
                    overflow: hidden;
                    overflow-y: scroll;
                    overflow-y: auto;
                }
            }

            .CollBTextboxOne {
                width: 96%;
                height: 80%;

                .CollText {
                    width: 100%;
                    height: 100%;
                    background-color: rgba(255, 255, 255, 0);
                    color: rgba(255, 255, 255, 1);
                    font-size: 1.8vh;
                    outline: none;
                    border: none;
                }
            }
        }
    }

    .CharBox {
        width: 100%;
        height: 80%;
        margin: 0 auto;
        margin-top: 3vh;
        display: flex;
        align-items: center;
        flex-direction: column;

        .RedBelow {
            width: 95%;
            height: 90%;
            display: flex;
            margin-top: 4vh;
            overflow: hidden;
            overflow-y: scroll;

            .RedLeft {
                width: 50%;
                // height: 100%;

                .RedName {
                    width: 90%;
                    height: 12%;
                    border-bottom: 1px solid rgba(100, 162, 255, 1);
                    margin-top: 3vh;
                    display: flex;
                    align-items: center;

                    input {
                        width: 55%;
                        -webkit-text-fill-color: rgba(255, 255, 255, 1); //颜色是设置成你需要的颜色
                        outline: none;
                        background-color: rgba(0, 0, 0, 0);
                        border: 0px;
                        text-indent: 2vh;
                        padding: 1vh;
                        font-size: 1.8vh;
                        color: rgba(255, 255, 255, 1);
                    }

                }

                .OrdSiteOne {
                    width: 100%;
                    height: 12%;
                    margin-top: 2vh;
                    margin-left: 1vh;

                    .OrRedClass {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;

                        .OrdDot {
                            width: 1vh;
                            height: 1vh;
                            border-radius: 50%;
                            background-color: rgba(100, 162, 255, 1);
                        }

                        .OrItem {}
                    }

                    /deep/ input::-webkit-input-placeholder {
                        color: rgba(255, 255, 255, 1);
                    }

                    /deep/ input::-moz-input-placeholder {
                        color: rgba(255, 255, 255, 1);
                    }

                    /deep/ input::-ms-input-placeholder {
                        color: rgba(255, 255, 255, 1);
                    }

                    // input框
                    /deep/ .el-select,
                    /deep/ .el-input,
                    /deep/ .el-input__inner {
                        width: 10vw;
                        // width:15vw;
                        // height:5vh;
                        background-color: rgba(255, 255, 255, 0);
                        color: rgba(255, 255, 255, 1);
                        border: 0px; // 去掉边框
                        font-size: 1.8vh;
                        font-weight: 300;


                    }

                    /deep/ .el-select .el-input .el-select__caret {
                        color: rgba(100, 162, 255, 1);
                    }

                    // option选项 最外层
                    /deep/ .el-select-dropdown {
                        border: none !important;
                        background: rgba(255, 255, 255, 1) !important;
                        z-index: 9999;
                    }
                }

                .RedNameOne {
                    width: 90%;
                    height: 12%;
                    border-bottom: 1px solid rgba(100, 162, 255, 1);
                    margin-top: 3vh;
                    display: flex;
                    align-items: center;

                    input {
                        width: 55%;
                        -webkit-text-fill-color: rgba(255, 255, 255, 1); //颜色是设置成你需要的颜色
                        outline: none;
                        background-color: rgba(0, 0, 0, 0);
                        border: 0px;
                        text-indent: 2vh;
                        padding: 1vh;
                        font-size: 1.8vh;
                        color: rgba(255, 255, 255, 1);
                    }

                    // 修改input默认值颜色 兼容其它主流浏览器
                    /deep/ input::-webkit-input-placeholder {
                        color: rgba(255, 255, 255, 1);
                    }

                    /deep/ input::-moz-input-placeholder {
                        color: rgba(255, 255, 255, 1);
                    }

                    /deep/ input::-ms-input-placeholder {
                        color: rgba(255, 255, 255, 1);
                    }

                    // input框
                    /deep/ .el-select,
                    /deep/ .el-input,
                    /deep/ .el-input__inner {
                        // width: 100%;
                        height: 5vh;
                        background-color: rgba(255, 255, 255, 0);
                        color: rgba(255, 255, 255, 1);
                        border: none; // 去掉边框
                        font-size: 1.8vh;
                        font-weight: 300;

                    }

                    /deep/ .el-select .el-input .el-select__caret {
                        color: rgba(100, 162, 255, 1);
                    }

                    // option选项 最外层
                    /deep/ .el-select-dropdown {
                        border: none !important;
                        background: rgba(255, 255, 255, 1) !important;
                        z-index: 9999;
                    }
                }

                .CharRightTwo {
                    width: 90%;
                    height: 30%;
                    margin-top: 4vh;
                    display: flex;
                    margin-left: 3vh;

                    //   border: 1px solid red;
                    .RedCharacters {
                        width: 25.1%;
                        font-size: 1.8vh;
                        color: rgba(255, 255, 255, 1);
                        height: 100%;

                        .RedPictureOne {
                            width: 100%;
                            height: 49%;
                        }
                    }

                    // .RedRedPictureTwo {
                    //         width: 100%;
                    //         height:100%;
                    //         display: flex;

                    //         /deep/ .el-upload {
                    //             width: 9vw;
                    //             height: 100%;
                    //             background-color: rgba(255, 255, 255, 0);
                    //             border: none;

                    //         }



                    //         ::v-deep .disUoloadBtnOne .el-upload--picture-card {
                    //             display: none;
                    //             /* 上传按钮隐藏 */
                    //         }

                    //         ::v-deep .el-upload-list--picture-card .el-upload-list__item {
                    //             // margin-top: 4%;
                    //             height: 100%;
                    //             width: 9vw;
                    //             // line-height: 80px;
                    //         }

                    //         // /deep/ 后要加一个空格

                    //         /deep/ .el-dialog {
                    //             width: 70%;
                    //             height: 70%;
                    //             display: flex;
                    //             justify-content: center;
                    //             align-items: center;


                    //             .image {
                    //                 width: 20vw;
                    //             }

                    //         }
                    //     }
                }

                .CharFlexOne {
                    width: 90%;
                    height: 12%;
                    margin-top: 5.8vh;
                    display: flex;
                    justify-content: space-between;

                    .RedPicture {
                        width: 48%;
                        height: 100%;
                        border-bottom: 1px solid rgba(100, 162, 255, 1);
                        display: flex;
                        align-items: center;

                        input {
                            width: 50%;
                            -webkit-text-fill-color: rgba(255, 255, 255, 1); //颜色是设置成你需要的颜色
                            outline: none;
                            background-color: rgba(0, 0, 0, 0);
                            border: 0px;
                            text-indent: 2vh;
                            padding: 1vh;
                            font-size: 1.8vh;
                            color: rgba(255, 255, 255, 1);
                        }
                    }
                }

                .RedUploading {
                    width: 100%;
                    height: 30%;
                    margin-top: 3.7vh;
                    display: flex;
                    margin-left: 3vh;

                    .RedCharacters {
                        width: 17.8%;
                        font-size: 1.8vh;
                        color: rgba(255, 255, 255, 1);
                        height: 100%;

                        .RedPictureOne {
                            width: 100%;
                            height: 49%;
                        }
                    }

                    .RedFlex {
                        width: 72%;
                        height: 85%;
                        // .RedRedPictureTwo {
                        //     width: 100%;
                        //     height: 49%;
                        //     display: flex;

                        //     /deep/ .el-upload {
                        //         width: 6.5vw;
                        //         height: 100%;
                        //         background-color: rgba(255, 255, 255, 0);
                        //         border: none;

                        //     }



                        //     ::v-deep .disUoloadBtn .el-upload--picture-card {
                        //         display: none;
                        //         /* 上传按钮隐藏 */
                        //     }

                        //     ::v-deep .el-upload-list--picture-card .el-upload-list__item {
                        //         // margin-top: 4%;
                        //         height: 12vh;
                        //         width: 6.5vw;
                        //         // line-height: 80px;
                        //     }

                        //     // /deep/ 后要加一个空格

                        //     /deep/ .el-dialog {
                        //         width: 70%;
                        //         height: 70%;
                        //         display: flex;
                        //         justify-content: center;
                        //         align-items: center;


                        //         .image {
                        //             width: 20vw;
                        //         }

                        //     }
                        // }

                        .RedVideo {
                            width: 100%;
                            height: 100%;
                            display: flex;

                            /deep/ .el-upload {
                                width: 9vw;
                                height: 100%;
                                background-color: rgba(255, 255, 255, 0);
                                border: none;
                            }

                            ::v-deep .disUoloadBtn .el-upload--picture-card {
                                display: none;
                                /* 上传按钮隐藏 */
                            }

                            ::v-deep .el-upload-list--picture-card .el-upload-list__item {
                                // margin-top: 4%;
                                height: 100%;
                                width: 9vw;
                            }

                            // /deep/ 后要加一个空格

                            /deep/ .el-dialog {
                                width: 70%;
                                height: 70%;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                .video {
                                    // width: 20vw;
                                    width: 100%;
                                    height: 100%;
                                }

                            }
                        }
                    }

                }

                .ChaVideoIntrodu {
                    width: 89.8%;
                    height: 80%;
                    background-image: url(../../../assets/IntangibleCultural/collect/zhongjuxing.png);
                    background-size: 100% 100%;
                    margin-top: 6.3vh;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .ChaTitle {
                        width: 95%;
                        color: rgba(255, 255, 255, 1);
                        font-size: 1.8vh;
                        margin-top: 2vh;

                    }

                    .RedCollText {
                        width: 95%;
                        height: 32%;
                        background-color: rgba(255, 255, 255, 0);
                        color: rgba(221, 221, 221, 1);
                        font-size: 1.8vh;
                        outline: none;
                        border: none;
                        resize: none;
                        margin-top: 2vh;
                    }
                }

            }

            .RedRight {
                width: 50%;
                height: 100%;

                .RedTextBox {
                    width: 90%;
                    height: 66%;
                    background-image: url(../../../assets/IntangibleCultural/collect/zhongjuxing.png);
                    background-size: 100% 100%;
                    margin-top: 6vh;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .ChaTitle {
                        width: 95%;
                        color: rgba(255, 255, 255, 1);
                        font-size: 1.8vh;
                        margin-top: 2vh;
                    }

                    .RedCollText {
                        width: 95%;
                        height: 75%;
                        background-color: rgba(255, 255, 255, 0);
                        color: rgba(221, 221, 221, 1);
                        font-size: 1.8vh;
                        outline: none;
                        border: none;
                        resize: none;
                        margin-top: 2vh;
                        // scrollbar-base-color:red
                    }

                    ::-webkit-scrollbar {
                        width: 0.3vw;
                        height: 0.1vh;
                        background-color: rgba(125, 151, 241, 1);
                    }

                    /* 滚动条上的滚动滑块 */
                    ::-webkit-scrollbar-thumb {
                        height: 0.3vh;
                        background-color: rgba(41, 98, 182, 1);
                        border-radius: 32px;
                    }
                }



                .CharFlexTwo {
                    width: 90%;
                    height: 12%;
                    margin-top: 5.25vh;
                    display: flex;
                    justify-content: space-between;

                    .RedPicture {
                        width: 48%;
                        height: 100%;
                        border-bottom: 1px solid rgba(100, 162, 255, 1);
                        display: flex;
                        align-items: center;

                        input {
                            width: 50%;
                            -webkit-text-fill-color: rgba(255, 255, 255, 1); //颜色是设置成你需要的颜色
                            outline: none;
                            background-color: rgba(0, 0, 0, 0);
                            border: 0px;
                            text-indent: 2vh;
                            padding: 1vh;
                            font-size: 1.8vh;
                            color: rgba(255, 255, 255, 1);
                        }
                    }
                }

                .CharRight {
                    width: 90%;
                    height: 26%;
                    margin-top: 4vh;
                    display: flex;
                    margin-left: 3vh;

                    //   border: 1px solid red;
                    .RedCharacters {
                        width: 25.1%;
                        font-size: 1.8vh;
                        color: rgba(255, 255, 255, 1);
                        height: 100%;

                        .RedPictureOne {
                            width: 100%;
                            height: 49%;
                        }
                    }

                    .RedRedPictureTwo {
                        width: 100%;
                        height: 100%;
                        // display: flex;

                        /deep/ .el-upload {
                            width: 9vw;
                            height: 16vh;
                            border: 1px solid blue;
                            background-color: rgba(255, 255, 255, 0);
                            // border: none;

                        }



                        ::v-deep .disUoloadBtn .el-upload--picture-card {
                            display: none;
                            /* 上传按钮隐藏 */
                        }

                        ::v-deep .el-upload-list--picture-card .el-upload-list__item {
                            // margin-top: 4%;
                            height: 16vh;
                            width: 9vw;
                            // line-height: 80px;
                        }

                        // /deep/ 后要加一个空格

                        /deep/ .el-dialog {
                            width: 70%;
                            height: 70%;
                            display: flex;
                            justify-content: center;
                            align-items: center;


                            .image {
                                width: 20vw;
                            }

                        }

                    }
                }

                .ChaVideoIntrodu {
                    width: 89.8%;
                    height: 80%;
                    background-image: url(../../../assets/IntangibleCultural/collect/zhongjuxing.png);
                    background-size: 100% 100%;
                    margin-top: 8.78vh;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .ChaTitle {
                        width: 95%;
                        color: rgba(255, 255, 255, 1);
                        font-size: 1.8vh;
                        margin-top: 2vh;
                    }

                    .RedCollText {
                        width: 95%;
                        height: 32%;
                        background-color: rgba(255, 255, 255, 0);
                        color: rgba(221, 221, 221, 1);
                        font-size: 1.8vh;
                        outline: none;
                        border: none;
                        resize: none;
                        margin-top: 2vh;
                        // scrollbar-base-color:red
                    }
                }

                // .RedTextBoxOne {
                //     width: 90%;
                //     height:80%;
                //     background-image: url(../../../assets/IntangibleCultural/collect/zhongjuxing.png);
                //     background-size: 100% 100%;
                //     margin-top: 6vh;
                //     display: flex;
                //     flex-direction: column;
                //     align-items: center;

                //     .ChaTitleOne {
                //         width: 95%;
                //         color: rgba(255, 255, 255, 1);
                //         font-size: 1.8vh;
                //         margin-top: 2vh;
                //     }

                //     .RedCollTextOne {
                //         width: 95%;
                //         height: 75%;
                //         background-color: rgba(255, 255, 255, 0);
                //         color: rgba(221, 221, 221, 1);
                //         font-size: 1.8vh;
                //         outline: none;
                //         border: none;
                //         resize: none;
                //         margin-top: 2vh;
                //         // scrollbar-base-color:red
                //     }

                //     ::-webkit-scrollbar {
                //         width: 0.3vw;
                //         height: 0.1vh;
                //         background-color: rgba(213, 213, 213, 1);
                //     }

                //     /* 滚动条上的滚动滑块 */
                //     ::-webkit-scrollbar-thumb {
                //         height: 0.3vh;
                //         background-color: white;
                //         border-radius: 32px;
                //     }
                // }


            }
        }

        ::-webkit-scrollbar {
            width: 0.3vw;
            height: 0.1vh;
            background-color: rgba(125, 151, 241, 1);
        }

        /* 滚动条上的滚动滑块 */
        ::-webkit-scrollbar-thumb {
            height: 0.3vh;
            background-color: rgba(41, 98, 182, 1);
            border-radius: 32px;
        }
    }

}

.CollBoxTwo {
    width: 94%;
    height: 99%;

    .CharBox {
        width: 100%;
        height: 90%;
        margin: 0 auto;
        margin-top: 3vh;
        // background-image: url(../../../assets/IntangibleCultural/collect/zhongjuxing.png);
        // background-size: 100% 100%;
        display: flex;
        align-items: center;
        flex-direction: column;



        .RedBelow {
            width: 95%;
            height: 90%;
            display: flex;
            margin-top: 4vh;
            overflow: hidden;
            overflow-y: scroll;

            .RedLeft {
                width: 50%;
                // height: 100%;

                .RedName {
                    width: 90%;
                    height: 12%;
                    border-bottom: 1px solid rgba(100, 162, 255, 1);
                    margin-top: 5vh;
                    display: flex;
                    align-items: center;

                    input {
                        width: 67%;
                        -webkit-text-fill-color: rgba(255, 255, 255, 1); //颜色是设置成你需要的颜色
                        outline: none;
                        background-color: rgba(0, 0, 0, 0);
                        border: 0px;
                        text-indent: 2vh;
                        padding: 1vh;
                        font-size: 1.8vh;
                        color: rgba(255, 255, 255, 1);
                    }

                }

                .RedNameOne {
                    width: 90%;
                    height: 12%;
                    border-bottom: 1px solid rgba(100, 162, 255, 1);
                    margin-top: 16.08vh;
                    display: flex;
                    align-items: center;

                    input {
                        width: 55%;
                        -webkit-text-fill-color: rgba(255, 255, 255, 1); //颜色是设置成你需要的颜色
                        outline: none;
                        background-color: rgba(0, 0, 0, 0);
                        border: 0px;
                        text-indent: 2vh;
                        padding: 1vh;
                        font-size: 1.8vh;
                        color: rgba(255, 255, 255, 1);
                    }

                    // 修改input默认值颜色 兼容其它主流浏览器
                    /deep/ input::-webkit-input-placeholder {
                        color: rgba(255, 255, 255, 1);
                    }

                    /deep/ input::-moz-input-placeholder {
                        color: rgba(255, 255, 255, 1);
                    }

                    /deep/ input::-ms-input-placeholder {
                        color: rgba(255, 255, 255, 1);
                    }

                    // input框
                    /deep/ .el-select,
                    /deep/ .el-input,
                    /deep/ .el-input__inner {
                        width: 100%;
                        background-color: rgba(255, 255, 255, 0);
                        color: rgba(255, 255, 255, 1);
                        border: none; // 去掉边框
                        font-size: 1.8vh;
                        font-weight: 300;
                    }

                    /deep/ .el-select .el-input .el-select__caret {
                        color: rgba(100, 162, 255, 1);
                    }

                    // option选项 最外层
                    /deep/ .el-select-dropdown {
                        border: none !important;
                        background: rgba(255, 255, 255, 1) !important;
                        z-index: 9999;
                    }
                }

                .CharFlexOne {
                    width: 90%;
                    height: 12%;
                    margin-top: 8vh;
                    display: flex;
                    justify-content: space-between;

                    .RedPicture {
                        width: 48%;
                        height: 100%;
                        border-bottom: 1px solid rgba(100, 162, 255, 1);
                        display: flex;
                        align-items: center;

                        input {
                            width: 50%;
                            -webkit-text-fill-color: rgba(255, 255, 255, 1); //颜色是设置成你需要的颜色
                            outline: none;
                            background-color: rgba(0, 0, 0, 0);
                            border: 0px;
                            text-indent: 2vh;
                            padding: 1vh;
                            font-size: 1.8vh;
                            color: rgba(255, 255, 255, 1);
                        }
                    }
                }

                .RedUploading {
                    width: 100%;
                    height: 30%;
                    margin-top: 3vh;
                    display: flex;
                    margin-left: 3vh;

                    .RedCharacters {
                        width: 17.8%;
                        font-size: 1.8vh;
                        color: rgba(255, 255, 255, 1);
                        height: 100%;

                        .RedPictureOne {
                            width: 100%;
                            height: 49%;
                        }
                    }

                    .RedFlex {
                        width: 72%;
                        height: 80%;

                        .RedVideo {
                            width: 100%;
                            height: 100%;
                            display: flex;

                            /deep/ .el-upload {
                                width: 9vw;
                                height: 100%;
                                background-color: rgba(255, 255, 255, 0);
                                border: none;
                                background-size: 100% 100%;
                            }

                            ::v-deep .disUoloadBtn .el-upload--picture-card {
                                display: none;
                                /* 上传按钮隐藏 */
                            }

                            ::v-deep .el-upload-list--picture-card .el-upload-list__item {
                                // margin-top: 4%;
                                height: 100%;
                                width: 9vw;
                            }

                            // /deep/ 后要加一个空格

                            /deep/ .el-dialog {
                                width: 70%;
                                height: 70%;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                .video {
                                    // width: 20vw;
                                    width: 100%;
                                    height: 100%;
                                }

                            }
                        }
                    }

                }

                .ChaVideoIntrodu {
                    width: 89.8%;
                    height: 80%;
                    background-image: url(../../../assets/IntangibleCultural/collect/zhongjuxing.png);
                    background-size: 100% 100%;
                    margin-top: 6vh;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .ChaTitle {
                        width: 95%;
                        color: rgba(255, 255, 255, 1);
                        font-size: 1.8vh;
                        margin-top: 2vh;
                    }

                    .RedCollText {
                        width: 95%;
                        height: 32%;
                        background-color: rgba(255, 255, 255, 0);
                        color: rgba(221, 221, 221, 1);
                        font-size: 1.8vh;
                        outline: none;
                        border: none;
                        resize: none;
                        margin-top: 2vh;
                    }
                }

            }

            .RedRight {
                width: 50%;
                height: 100%;

                .RedTextBox {
                    width: 90%;
                    height: 55%;
                    background-image: url(../../../assets/IntangibleCultural/collect/zhongjuxing.png);
                    background-size: 100% 100%;
                    margin-top: 6vh;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    // justify-content: center;
                    .ChaTitle {
                        width: 95%;
                        color: rgba(255, 255, 255, 1);
                        font-size: 1.8vh;
                        margin-top: 2vh;
                    }

                    .RedCollText {
                        width: 95%;
                        height: 75%;
                        background-color: rgba(255, 255, 255, 0);
                        color: rgba(221, 221, 221, 1);
                        font-size: 1.8vh;
                        outline: none;
                        border: none;
                        resize: none;
                        margin-top: 2vh;
                        // scrollbar-base-color:red
                    }

                    ::-webkit-scrollbar {
                        width: 0.3vw;
                        height: 0.1vh;
                        background-color: rgba(213, 213, 213, 1);
                    }

                    /* 滚动条上的滚动滑块 */
                    ::-webkit-scrollbar-thumb {
                        height: 0.3vh;
                        background-color: white;
                        border-radius: 32px;
                    }
                }



                .CharFlexTwo {
                    width: 90%;
                    height: 12%;
                    margin-top: 2vh;
                    display: flex;
                    justify-content: space-between;

                    .RedPicture {
                        width: 48%;
                        height: 100%;
                        border-bottom: 1px solid rgba(100, 162, 255, 1);
                        display: flex;
                        align-items: center;

                        input {
                            width: 50%;
                            -webkit-text-fill-color: rgba(255, 255, 255, 1); //颜色是设置成你需要的颜色
                            outline: none;
                            background-color: rgba(0, 0, 0, 0);
                            border: 0px;
                            text-indent: 2vh;
                            padding: 1vh;
                            font-size: 1.8vh;
                            color: rgba(255, 255, 255, 1);
                        }
                    }
                }

                .CharRight {
                    width: 90%;
                    height: 30%;
                    margin-top: 3vh;
                    display: flex;
                    margin-left: 3vh;

                    .RedCharacters {
                        width: 25.1%;
                        font-size: 1.8vh;
                        color: rgba(255, 255, 255, 1);
                        height: 100%;

                        .RedPictureOne {
                            width: 100%;
                            height: 49%;
                        }
                    }

                    .RedRedPictureTwo {
                        width: 100%;
                        height: 100%;

                        .RedVideo {
                            width: 100%;
                            height: 80%;
                            border: 5px solid rgb(116, 0, 249);


                            // display: flex;


                            /deep/ .el-upload {
                                width: 9vw;
                                height: 100%;
                                background-color: rgba(255, 255, 255, 0);
                                border: none;
                                // display:flex;

                            }



                            ::v-deep .disUoloadBtn .el-upload--picture-card {
                                display: none;
                                /* 上传按钮隐藏 */
                            }

                            ::v-deep .el-upload-list--picture-card .el-upload-list__item {
                                // margin-top: 4%;
                                height: 16vh;
                                width: 9vw;
                                // line-height: 80px;
                            }

                            // /deep/ 后要加一个空格

                            /deep/ .el-dialog {
                                width: 70%;
                                height: 70%;
                                display: flex;
                                justify-content: center;
                                align-items: center;


                                .image {
                                    width: 20vw;
                                }

                            }
                        }
                    }
                }

                .ChaVideoIntrodu {
                    width: 89.8%;
                    height: 80%;
                    background-image: url(../../../assets/IntangibleCultural/collect/zhongjuxing.png);
                    background-size: 100% 100%;
                    margin-top: 6vh;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .ChaTitle {
                        width: 95%;
                        color: rgba(255, 255, 255, 1);
                        font-size: 1.8vh;
                        margin-top: 2vh;
                    }

                    .RedCollText {
                        width: 95%;
                        height: 32%;
                        background-color: rgba(255, 255, 255, 0);
                        color: rgba(221, 221, 221, 1);
                        font-size: 1.8vh;
                        outline: none;
                        border: none;
                        resize: none;
                        margin-top: 2vh;
                        // scrollbar-base-color:red
                    }
                }

            }
        }

        ::-webkit-scrollbar {
            width: 0.3vw;
            height: 0.1vh;
            background-color: rgba(125, 151, 241, 1);
        }

        /* 滚动条上的滚动滑块 */
        ::-webkit-scrollbar-thumb {
            height: 0.3vh;
            background-color: rgba(41, 98, 182, 1);
            border-radius: 32px;
        }
    }




}</style>